import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'modal-cambiar-frontera',
  templateUrl: './modal-cambiar-frontera.component.html',
  styleUrls: ['./modal-cambiar-frontera.component.css']
})
export class ModalCambiarFronteraComponent implements OnInit {
 
  constructor() { }

  myform = new FormGroup({
    aduanas: new FormControl(null, [Validators.required, this.validateType]),

  })

  ngOnInit(): void {
  }

  /**
* Validar que cuando realizan busqueda en los dropdowns no puede ser un texto, sino un elemento del mismo arreglo.
* @param control
*/
  validateType(control: AbstractControl) {
    if (typeof control.value === 'string') {
      return { invalid: true };
    }
    return null;
  }

  clear(ctrl: FormControl) {
    ctrl.setValue(null);
  }

  displayFn(item: { Id: string, Descripcion: string }): string {
    return item && item.Descripcion ? item.Descripcion : null;
  }


}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityService } from 'ng-metro4';
import { finalize, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorSpinnerService implements HttpInterceptor {

  constructor(private activityService: ActivityService ) { }

  spinner: number = 0;
  activitySpinner: any;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.RequestInterceptor();
    const handler = req.clone();
    return next.handle(handler).pipe(finalize(() => {this.ResponseInterceptor()}));
  }

  private RequestInterceptor(): void {
    this.spinner++;
    if(this.spinner == 1){
      this.createSpinner();
    }      
  }

  private ResponseInterceptor(): void {
    if (this.spinner > 0) {
      this.spinner -= 1;
    }
    if (this.spinner == 0) {
        this.activityService.close(this.activitySpinner);
    }
  }

  private createSpinner(){
    this.activitySpinner = this.activityService.open(
      {
        style: 'color',
        text: 'Cargando...',
        type: 'cycle'
      }
    )
  }
}

<div class="cardContainer">
  <div class="card c-pointer" (click)="EventOpenModalCrearP()">
    <div>
      <img src="../../assets/icons/mas.png" alt="Alternate Text" />
    </div>
    <div>
      <span><strong>Crear nueva plantilla</strong></span>
    </div>
  </div>

  <div class="carousel-container-cc" style="">
    <div
      class="carousel-cc"
      [style.transform]="
        'translateX(-' + currentIndex * (100 / itemsToShow) + '%)'
      "
    >
      <div
        *ngFor="let plantilla of listPlantillasFavs"
        style=""
        class="carousel-item-cc"
      >
        <div class="card c-pointer" (click)="openDialog(plantilla)">
          <div>
            <img src="../../assets/icons/analisis2.png" alt="Alternate Text" />
          </div>
          <span
            ><strong>{{ plantilla.Nombre }}</strong></span
          >
        </div>
      </div>
    </div>
    <button class="carousel-button prev" (click)="prev()">&#10094;</button>
    <button class="carousel-button next" (click)="next()">&#10095;</button>
  </div>
</div>
<br />

<!--<ngb-carousel [showNavigationArrows]="true "  [showNavigationIndicators]="false">-->

<!--<div class="flexRowNoWrap rowCard " style="overflow-x: auto;">-->
<!--<ng-template ngbSlide *ngFor="let plantilla of listPlantillasFavs" style="height: 150px" class="flexRowNoWrap">
        <div class=" card c-pointer" (click)="openDialog(plantilla)">
          <div>
            <img src="../../assets/icons/analisis2.png" alt="Alternate Text" />
          </div>
          <span><strong>{{plantilla.Nombre}}</strong></span>
        </div>
      </ng-template>-->

<!--</div>-->

<!--</ngb-carousel>-->

<div class="SectionGrids">
  <div style="">
    <!--************Encabezado***********-->

    <div
      class="flexRowNoWrap EncabezadoGridR1"
      style="justify-content: space-between; flex-wrap: nowrap"
    >
      <div
        class="cellListP"
        style="min-width: 300px; display: unset"
        (mouseenter)="showSortButton('Nombre')"
        (mouseleave)="hideSortButton('Nombre')"
      >
        <div class="flexRowNoWrap">
          <div class="cellListE1"><span>Nombre</span></div>
          <div
            class="sort-icon"
            *ngIf="showSort['Nombre']"
            (click)="sortColumn('Nombre')"
          >
            <img
              src="../../../assets/icons/ordenar.png"
              title="Ordenar"
              style="height: 32px; width: 30px"
            />
          </div>
        </div>
      </div>

      <div class="cellListP" style="">
        <div class="cellListE1"><span>Creado Por</span></div>
      </div>

      <div class="cellListP">
        <div class="cellListE1"><span>Fecha Creación/Modificación</span></div>
      </div>

      <div class="cellListP" style="min-width: 90px; max-width: 90px">
        <div class="cellListE1"><span>Dia Inicio</span></div>
      </div>

      <div class="cellListP" style="min-width: 90px; max-width: 90px">
        <div class="cellListE1"><span>Dia Fin</span></div>
      </div>

      <div class="cellListP" style="min-width: 55px; max-width: 55px">
        <div class="cellListE1"><span>Editar</span></div>
      </div>

      <div class="cellListP" style="min-width: 65px; max-width: 65px">
        <div class="cellListE1"><span>Eliminar</span></div>
      </div>
    </div>
  </div>
  <!--************Body grids***********-->
  <div>
    <div class="subsection" style="">
      <div *ngFor="let item of listPlantillas; let i = index">
        <div
          class="flexRowNoWrap rowGrid c-pointer"
          (dblclick)="openDialog(item)"
        >
          <div class="cellListP" style="min-width: 300px">
            <div class="cellListE1">
              <span>{{ item.Nombre }}</span>
            </div>
          </div>

          <div class="cellListP" style="">
            <div class="cellListE1">
              <span>{{ item.CreatedBy }}</span>
            </div>
          </div>

          <div class="cellListP" style="">
            <div class="cellListE1">
              <span>{{ item.LastDate }}</span>
            </div>
          </div>

          <div class="cellListP" style="min-width: 90px; max-width: 90px">
            <div class="cellListE1">
              <span>{{ item.DiaMin }}</span>
            </div>
          </div>

          <div class="cellListP" style="min-width: 90px; max-width: 90px">
            <div class="cellListE1">
              <span>{{ item.DiaMax }}</span>
            </div>
          </div>

          <div class="cellListP" style="min-width: 55px; max-width: 55px">
            <button
              class="buttonTransparente transparent"
              style="min-width: 36px; height: 30px"
              (click)="EventOpenModalUpdateP(item)"
            >
              <img
                src="../../assets/icons/ic_edit.png"
                alt="Alternate Text"
                style="height: 20px"
              />
            </button>
          </div>

          <div class="cellListP" style="min-width: 65px; max-width: 65px">
            <button
              class="buttonTransparente transparent"
              style="min-width: 36px; height: 30px"
              (click)="EventOpenModalDeleteP(item)"
            >
              <img
                src="../../assets/icons/borrar.png"
                alt="Alternate Text"
                style="height: 20px"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<button mat-button >Open dialog</button>-->

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import  Enumerable from 'linq';
import { CorteDiarioModel } from '../corte-diario.models';
import { ModalCambiarFronteraComponent } from '../modal-cambiar-frontera/modal-cambiar-frontera.component';

@Component({
  selector: 'grid-rutas',
  templateUrl: './grid-rutas.component.html',
  styleUrls: ['./grid-rutas.component.css']
})
export class GridRutasComponent implements OnInit {

  constructor(public dialog: MatDialog) { }


  ngOnInit(): void {
    //this.listRutas.push(
    //  { Ruta: 'Test1', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //  { Ruta: 'Test2', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //  { Ruta: 'Test3', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //  { Ruta: 'Test4', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //  { Ruta: 'Test5', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //  { Ruta: 'Test6', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //  { Ruta: 'Test7', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //  { Ruta: 'Test8', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //  { Ruta: 'Test9', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //  { Ruta: 'Test10', Gestion: 3, SKU: 2, Aduana: 'Aduana XXX' },
    //);

  }

 




}

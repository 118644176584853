import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Enumerable from 'linq';
import { CorteDiarioModel } from '../corte-diario.models';
import * as moment from 'moment';
import { ModalBitacoraComponent } from '../modal-bitacora/modal-bitacora.component';
import { ModalFacturaComponent } from '../modal-factura/modal-factura.component';
import { ModalNotasComponent } from '../modal-notas/modal-notas.component';
import { ModalEventosComponent } from '../modal-eventos/modal-eventos.component';
import { ModalAlertasComponent } from '../modal-alertas/modal-alertas.component';

@Component({
  selector: 'modal-gestiones',
  templateUrl: './modal-gestiones.component.html',
  styleUrls: ['./modal-gestiones.component.css'],
})
export class ModalGestionesComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      gestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>;
      tipo: string;
      usuarioId: string;
    }
  ) {}
  public showTooltip: boolean = false;
  public title = '';
  public listGestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> = [];
  public listGestionesCopy: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
    [];
  public newArrayGestion: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
    [];
  public newArrayGestionCopy: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
    [];
  public arrayListItem: Array<{ Descripcion: string }> = [];
  public listFacturas: Array<{ NumeroFactura: string }> = [];
  public listNotas: Array<any> = [];
  public totalGestionesMadre: number = 0;
  public totalGestionesHija: number = 0;
  positionsArrays = { top: '0px', left: '0px' };

  ngOnInit(): void {
    this.listGestiones = this.data.gestiones;
    this.listGestionesCopy = [...this.data.gestiones];
    this.ngGenerateGrid();
  }

  ngGenerateGrid() {
    console.log(this.listGestiones);

    this.newArrayGestion =
      this.data.tipo == 'Origen'
        ? Enumerable.from(this.listGestiones)
            .where((s) => s.Segmentacion.Origen?.SitioActual == true)
            .toArray()
        : this.data.tipo == 'TransitoUno'
        ? Enumerable.from(this.listGestiones)
            .where((s) =>
              s.Segmentacion.TransitoUno.some(
                (st1) => st1?.SitioActual == true || st1?.SegmentoActual == true
              )
            )
            .toArray()
        : this.data.tipo == 'Frontera'
        ? Enumerable.from(this.listGestiones)
            .where((s) =>
              s.Segmentacion.Frontera?.some((st1) => st1.SitioActual == true)
            )
            .toArray()
        : this.data.tipo == 'TransitoDos'
        ? Enumerable.from(this.listGestiones)
            .where((s) =>
              s.Segmentacion.TransitoDos.some(
                (st1) => st1?.SitioActual == true || st1?.SegmentoActual == true
              )
            )
            .toArray()
        : this.data.tipo == 'Destino'
        ? Enumerable.from(this.listGestiones)
            .where((s) =>
              s.Segmentacion.Destino?.some((st1) => st1.SitioActual == true)
            )
            .toArray()
        : this.listGestiones;
    console.log(this.newArrayGestion);
    if (this.newArrayGestion.length > 0) {
      this.newArrayGestion.forEach((gestion) => {
        gestion.CostoTotal = parseFloat(gestion.CostoTotal.toFixed(2));
        if (gestion.Segmentacion.Origen.SitioActual == true) {
          gestion.SitioActual = gestion.Segmentacion.Origen.Nombre;
          let eta = Enumerable.from(gestion.Alertas)
            .where((s) => s.SitioId == gestion.Segmentacion.Origen.SitioId)
            .select((s) => s.Eta)
            .firstOrDefault();
          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        } else if (
          gestion.Segmentacion.TransitoUno.some(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          )
        ) {
          let nombre = gestion.Segmentacion.TransitoUno.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).Nombre;
          gestion.SitioActual =
            nombre != undefined && nombre != null ? nombre : 'Transito 1';

          let id = gestion.Segmentacion.TransitoUno.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).SitioId;
          let eta = gestion.Segmentacion.TransitoUno.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).Eta;
          //let eta = Enumerable.from(gestion.Alertas).where(s => s.SitioId == id).select(s => s.Eta).firstOrDefault();

          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        } else if (
          gestion.Segmentacion.Frontera.some((st1) => st1.SitioActual == true)
        ) {
          gestion.SitioActual = gestion.Segmentacion.Frontera.find(
            (st1) => st1.SitioActual == true
          ).Nombre;
          let eta = Enumerable.from(gestion.Alertas)
            .where((s) =>
              gestion.Segmentacion.Frontera.some(
                (sf) => sf.SitioId == s.SitioId
              )
            )
            .select((s) => s.Eta)
            .firstOrDefault();
          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        } else if (
          gestion.Segmentacion.TransitoDos.some(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          )
        ) {
          let nombre = gestion.Segmentacion.TransitoDos.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).Nombre;
          gestion.SitioActual =
            nombre != undefined && nombre != null ? nombre : 'Transito 2';
          let id = gestion.Segmentacion.TransitoDos.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).SitioId;
          let eta = gestion.Segmentacion.TransitoDos.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).Eta;
          //let eta = Enumerable.from(gestion.Alertas).where(s => s.SitioId == id).select(s => s.Eta).firstOrDefault();
          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        } else if (
          gestion.Segmentacion.Destino.some((st1) => st1.SitioActual == true)
        ) {
          gestion.SitioActual = gestion.Segmentacion.Destino.find(
            (st1) => st1.SitioActual == true
          ).Nombre;
          let eta = Enumerable.from(gestion.Alertas)
            .where((s) =>
              gestion.Segmentacion.Destino.some((sf) => sf.SitioId == s.SitioId)
            )
            .select((s) => s.Eta)
            .firstOrDefault();
          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        }

        //const array = Object.entries(gestion.Segmentacion).map(([clave, valor]) => ({
        //  clave,
        //  valor: Array.isArray(valor) ? valor : Object.entries(valor)
        //}));
        //console.log(array);

        // gestion.UltimoSello = gestion.UltimoSello.toString() != '0001-01-01T00:00:00' ? moment(gestion.UltimoSello, moment.ISO_8601).format('DD-MM-YYYY') : 'Sin Fecha';

        const fechaMoment = moment(gestion.UltimoSello, 'YYYY-MM-DDTHH:mm:ss');
        if (fechaMoment.isValid()) {
          gestion.UltimoSello = fechaMoment.format('DD-MM-YYYY');
          gestion.UltimoSello =
            gestion.UltimoSello.toString() === '01-01-0001'
              ? 'Sin Fecha'
              : gestion.UltimoSello;
        } else {
          gestion.UltimoSello = 'Sin Fecha';
        }
      });
    }
    this.newArrayGestion.forEach((gestion) => {
      gestion.Expandible = false;
      gestion.GestionesHijas.forEach((gestionH) => {
        gestionH.SitioActual = gestionH.Segmentacion?.Origen?.Nombre;
        let eta = Enumerable.from(gestionH.Alertas)
          .where((s) => s.SitioId == gestionH.Segmentacion?.Origen.SitioId)
          .select((s) => s.Eta)
          .firstOrDefault();
        gestionH.ETA = moment(eta).format('DD-MM-YYYY');
      });
      //gestion.UltimoSello = gestion.UltimoSello.toString() != '0001-01-01T00:00:00' && gestion.UltimoSello.toString() != "Invalid date" && gestion.UltimoSello.toString() != 'Sin Fecha' ? moment(new Date(gestion.UltimoSello)).format('DD-MM-YYYY') : 'Sin Fecha';
    });
    this.newArrayGestionCopy = [...this.newArrayGestion];
    // Contar gestiones madre que estén en estado Finalizado
    this.totalGestionesMadre = Enumerable.from(this.newArrayGestion)
      .where((item) => item.Estado.DisplayName !== 'Finalizado') // Filtrar las madres finalizadas
      .count();

    // Contar gestiones hija solo si la madre está finalizada
    this.totalGestionesHija = Enumerable.from(this.newArrayGestion)
      .where((item) => item.Estado.DisplayName === 'Finalizado') // Solo contar hijas si la madre está finalizada
      .selectMany((item) => item.GestionesHijas || [])
      .count();

    this.ProcesarSitioGestiones();
    console.log('this.newArrayGestion', this.newArrayGestion);
  }

  ProcesarSitioGestiones() {
    // Procesar gestión madre
    this.newArrayGestion = Enumerable.from(this.newArrayGestion)
      .select((gestion: any) => {
        // Si está Finalizado, limpiar SitioActual
        if (gestion.Estado?.DisplayName === 'Finalizado') {
          gestion.SitioActual = '';
        }

        // Procesar gestiones hijas si existen
        if (gestion.GestionesHijas && gestion.GestionesHijas.length > 0) {
          gestion.GestionesHijas = gestion.GestionesHijas.map(
            (gestionHija: any) => {
              // Procesar segmentación
              const segmentaciones = [
                'Origen',
                'TransitoUno',
                'Frontera',
                'TransitoDos',
                'Destino',
              ];

              segmentaciones.forEach((segmentoKey) => {
                if (gestionHija?.Segmentacion) {
                  const segmento = gestionHija.Segmentacion[segmentoKey];

                  if (segmentoKey === 'Origen') {
                    // Manejar 'Origen' como un objeto JSON
                    if (segmento && segmento.SitioActual) {
                      gestionHija.SitioActualModal = segmento.Nombre;
                    }
                  } else if (Array.isArray(segmento)) {
                    // Buscar SitioActual = true en arrays para otros segmentos
                    const sitioActual = segmento.find(
                      (seg: any) =>
                        (seg && seg.SitioActual === true) ||
                        seg?.SegmentoActual === true
                    );
                    if (sitioActual) {
                      gestionHija.SitioActualModal = sitioActual.Nombre;
                    }
                  }
                }
              });

              return gestionHija;
            }
          );
        }

        return gestion;
      })
      .toArray();
  }

  EventModalBitacora(gestion: CorteDiarioModel.GetGestionesAbiertasXRutas) {
    const dialogRef = this.dialog.open(ModalBitacoraComponent, {
      width: '90%', // Ancho del diálogo
      height: '90%', // Alto del diálogo
      disableClose: true,
      data: {
        gestionSelected: gestion,
        usuarioid: this.data.usuarioId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  EventModalFactura(
    gestion: CorteDiarioModel.GetGestionesAbiertasXRutas,
    listFacturas
  ) {
    const dialogRef = this.dialog.open(ModalFacturaComponent, {
      width: '30%', // Ancho del diálogo
      height: '30%', // Alto del diálogo
      disableClose: true,
      data: {
        gestionSelected: gestion,
        usuarioid: this.data.usuarioId,
        listFacturas: listFacturas,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  EventModalNotas(
    gestion: CorteDiarioModel.GetGestionesAbiertasXRutas,
    listNotas
  ) {
    const dialogRef = this.dialog.open(ModalNotasComponent, {
      width: '90%', // Ancho del diálogo
      height: '90%', // Alto del diálogo
      disableClose: true,
      data: {
        gestionSelected: gestion,
        usuarioid: this.data.usuarioId,
        listNotas: listNotas,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  EventModalEventos(
    gestion: CorteDiarioModel.GetGestionesAbiertasXRutas,
    listEventos,
    listEventosCerrados,
    listEventosAbiertos
  ) {
    const dialogRef = this.dialog.open(ModalEventosComponent, {
      width: '90%', // Ancho del diálogo
      height: '90%', // Alto del diálogo
      disableClose: true,
      data: {
        gestionSelected: gestion,
        usuarioid: this.data.usuarioId,
        listEventos: listEventos,
        listEventosCerrados: listEventosCerrados,
        listEventosAbiertos: listEventosAbiertos,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  EventModalAlertas(
    gestion: CorteDiarioModel.GetGestionesAbiertasXRutas,
    listAlertas
  ) {
    const dialogRef = this.dialog.open(ModalAlertasComponent, {
      width: '90%', // Ancho del diálogo
      height: '90%', // Alto del diálogo
      disableClose: true,
      data: {
        gestionSelected: gestion,
        usuarioid: this.data.usuarioId,
        listAlertas: listAlertas,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  Eventtooltip(
    gestion: CorteDiarioModel.GetGestionesAbiertasXRutas,
    type: string
  ) {
    console.log('tooltip');
    console.log(gestion);
    //this.arrayFamiliasByPLantilla = arrayFamilias;
    this.arrayListItem = [];
    if (gestion != null) {
      if (type == 'Facturas') {
        gestion.CostosFacturacion.forEach((s) => {
          this.arrayListItem.push({ Descripcion: s.NumeroFactura });
        });
        this.title = 'Factura Comercial';
      } else if (type == 'DocumentoT') {
        console.log(gestion.DocumentosTransporte);
        gestion.DocumentosTransporte.forEach((s) => {
          this.arrayListItem.push({ Descripcion: s.CorrelativoDocumento });
        });
        this.title = 'Documento Transporte';
      }
    }
    this.arrayListItem = [...this.arrayListItem];
    console.log(this.arrayListItem);
    let id = type;
    let elemento = document.getElementById(id);
    console.log(elemento);
    if (elemento != null) {
      let posicion = elemento.getBoundingClientRect();
      console.log(posicion);

      this.positionsArrays = {
        top: Math.abs(posicion.top) + 80 + 'px',
        left: Math.abs(posicion.left) + 100 + 'px',
      };
    }

    this.showTooltip = !this.showTooltip;
  }
  MostrarFacturas(gestion) {
    // Inicializar la lista de facturas vacía
    this.listFacturas = [];

    // Agregar las facturas de la gestión principal si existen
    if (gestion.CostosFacturacion) {
      this.listFacturas.push(...gestion.CostosFacturacion);
    }

    // Agregar las facturas de cada gestión hija, si existen
    if (gestion?.GestionesHijas?.length > 0) {
      gestion.GestionesHijas.forEach((hija) => {
        if (hija.CostosFacturacion) {
          this.listFacturas.push(...hija.CostosFacturacion);
        }
      });
    }

    // Usar Enumerable para obtener solo números de factura únicos
    this.listFacturas = Enumerable.from(this.listFacturas)
      .distinct((factura) => factura.NumeroFactura) // Elimina duplicados por NumeroFactura
      .select((factura) => ({ NumeroFactura: factura.NumeroFactura })) // Selecciona solo los objetos con el campo NumeroFactura
      .toArray();

    // Abrir el modal con la lista de facturas
    this.EventModalFactura(gestion, this.listFacturas);
  }

  MostrarNotas(gestion) {
    this.listNotas = [];

    if (gestion.Notas) {
      this.listNotas.push(...gestion.Notas);
    }

    if (gestion?.GestionesHijas?.length > 0) {
      gestion.GestionesHijas.forEach((hija) => {
        if (hija.Notas) {
          this.listNotas.push(...hija.Notas);
        }
      });
    }

    this.listNotas = Enumerable.from(this.listNotas)
      .orderByDescending((nota) => new Date(nota.CreatedDate))
      .select((Nota) => ({
        CreatedByName: Nota.CreatedByName,
        CreatedDate: Nota.CreatedDate,
        Observacion: Nota.Observacion,
        ObservacionSegmento:
          Nota.Sitio?.Nombre || Nota.Segmento?.Observacion || '',
      }))
      .toArray();

    console.log('this.listNotas', this.listNotas);
    this.EventModalNotas(gestion, this.listNotas);
  }

  MostrarAlertas(gestion) {
    let listAlertas = [];

    if (gestion.Alertas) {
      listAlertas.push(...gestion.Alertas);
    }

    if (gestion?.GestionesHijas?.length > 0) {
      gestion.GestionesHijas.forEach((hija) => {
        if (hija.Alertas) {
          listAlertas.push(...hija.Alertas);
        }
      });
    }

    listAlertas = Enumerable.from(listAlertas)
      .select((alerta) => ({
        FechaEfectiva: moment(alerta.FechaEfectiva).format('DD-MM-YYYY HH:mm'),
        CategoriaDescripcion: alerta.CategoriaDescripcion,
        ValorImpacto: alerta.ValorImpacto,
        UsuarioNombre: alerta.UsuarioNombre,
        SistemaAlertaTipoDescripcion: alerta.SistemaAlertaTipoDescripcion,
        NivelDescripcion: alerta.NivelDescripcion,
      }))
      .toArray();

    this.EventModalAlertas(gestion, listAlertas);
  }

  MostrarEventos(gestion) {
    const listEventos = [];
    const eventosCerrados = [];
    const eventosAbiertos = [];

    if (gestion.Eventos) {
      listEventos.push(...gestion.Eventos);
    }

    if (gestion?.GestionesHijas?.length > 0) {
      gestion.GestionesHijas.forEach((hija) => {
        if (hija.Eventos) {
          listEventos.push(...hija.Eventos);
        }
      });
    }

    listEventos.forEach((event) => {
      if (event.Estado == 'Cerrado') {
        eventosCerrados.push(event);
      } else if (event.Estado == 'Abierto') {
        eventosAbiertos.push(event);
      }
    });

    const listEventosCerrados = Enumerable.from(eventosCerrados)
      .select((evento) => ({
        CreatedDate: moment(evento.CreatedDate).format('DD-MM-YYYY HH:mm'),
        SitioNombre: evento.SitioNombre,
        DisplayName: evento.EventoTipo?.Categoria?.DisplayName,
        Tipo: evento.EventoTipo?.Descripcion,
        Inicio: moment(evento.Inicio).format('DD-MM-YYYY HH:mm'),
        Fin: moment(evento.Fin).format('DD-MM-YYYY HH:mm'),
        CreatedByNombre: evento.CreatedByNombre,
      }))
      .toArray();

    const listEventosAbiertos = Enumerable.from(eventosAbiertos)
      .select((evento) => ({
        CreatedDate: moment(evento.CreatedDate).format('DD-MM-YYYY HH:mm'),
        SitioNombre: evento.SitioNombre,
        DisplayName: evento.EventoTipo?.Categoria?.DisplayName,
        Tipo: evento.EventoTipo?.Descripcion,
        Inicio: moment(evento.Inicio).format('DD-MM-YYYY HH:mm'),
        Fin: moment(evento.Fin).format('DD-MM-YYYY HH:mm'),
        CreatedByNombre: evento.CreatedByNombre,
      }))
      .toArray();

    this.EventModalEventos(
      gestion,
      listEventos,
      listEventosCerrados,
      listEventosAbiertos
    );
  }

  mostrarEventosAbiertos(gestion) {
    const listEventos = [];

    // Recolectar todos los eventos de la gestión y sus gestiones hijas
    if (gestion.Eventos) {
      listEventos.push(...gestion.Eventos);
    }

    if (gestion?.GestionesHijas?.length > 0) {
      gestion.GestionesHijas.forEach((hija) => {
        if (hija.Eventos) {
          listEventos.push(...hija.Eventos);
        }
      });
    }

    // Verificar si hay algún evento abierto
    const tieneAbiertos = listEventos.some(
      (event) => event.Estado !== 'Cerrado'
    );

    return {
      totalEventos: listEventos.length,
      tieneAbiertos,
    };
  }

  mostrarAlertasColor(gestion) {
    const listAlertas = [];

    // Recolectar todas las alertas de la gestión y sus gestiones hijas
    if (gestion.Alertas) {
      listAlertas.push(...gestion.Alertas);
    }

    if (gestion?.GestionesHijas?.length > 0) {
      gestion.GestionesHijas.forEach((hija) => {
        if (hija.Alertas) {
          listAlertas.push(...hija.Alertas);
        }
      });
    }

    // Retornar la cantidad de alertas
    return {
      totalAlertas: listAlertas.length,
    };
  }

  obtenerUltimaObservacion(gestion): string {
    let notas = [];

    // Verificamos si la gestión tiene notas y las añadimos a la lista
    if (gestion.Notas && gestion.Notas.length > 0) {
      notas.push(...gestion.Notas);
    }

    // Verificamos si la gestión tiene gestiones hijas
    if (gestion?.GestionesHijas && gestion?.GestionesHijas?.length > 0) {
      gestion.GestionesHijas.forEach((hija) => {
        if (hija.Notas && hija.Notas.length > 0) {
          notas.push(...hija.Notas);
        }
      });
    }

    // Ordenamos las notas por fecha de creación en orden descendente
    const ultimaNota = Enumerable.from(notas)
      .orderByDescending((nota) => new Date(nota.CreatedDate))
      .firstOrDefault();

    // Retornamos la última observación si existe, de lo contrario un texto vacío
    return ultimaNota ? ultimaNota.Observacion : '';
  }

  public filtersValues: Array<CorteDiarioModel.FiltrosGrid> = [];

  EventFiltros(event, descripcion) {
    console.log(event);
    console.log(event.target.name);
    console.log(event.target.value);
    console.log(descripcion);
    if (event.target.value == '' || event.target.value == null) {
      if (this.filtersValues.some((s) => s.key == event.target.name)) {
        //elimina el valor si este es vacio
        let idndex = this.filtersValues.findIndex(
          (s) => s.key == event.target.name
        );
        if ((idndex = -1)) {
          this.filtersValues.splice(idndex, 1);
        }
      }
    } else {
      if (this.filtersValues.some((s) => s.key == event.target.name)) {
        //si ya existe un filtro reemplaza el valor
        let idndex = this.filtersValues.findIndex(
          (s) => s.key == event.target.name
        );
        if (idndex != -1) {
          this.filtersValues[idndex].value = event.target.value;
        }
      } else {
        //Sino lo agrega como uno nuevo
        this.filtersValues.push({
          key: event.target.name,
          value: event.target.value,
          descripcion: descripcion,
        });
      }
    }
    console.log(this.filtersValues);
    this.filterData(this.filtersValues);
  }

  filterData(filters: CorteDiarioModel.FiltrosGrid[]) {
    let context = this;
    // Supongamos que 'data' es la variable que contiene tu array de objetos
    let Data = this.newArrayGestion;

    this.newArrayGestion = [...this.newArrayGestionCopy];

    //reiniciar el array cada vez que se agrega un filtro
    //context.listGestiones = JSON.parse(JSON.stringify(context.listGestionesCopy));
    //filters.forEach(filtro => {

    //  filtro.descripcion

    //  this.newArrayGestion = Enumerable.from(this.newArrayGestionCopy).where(s=>s.)
    //});

    this.newArrayGestion = this.newArrayGestionCopy.filter((item) => {
      return filters.every((filter) => {
        let value =
          item[filter.key as keyof CorteDiarioModel.GetGestionesAbiertasXRutas];

        if (filter.key == 'CostosFacturacion') {
          return (
            value != null &&
            Array.isArray(value) &&
            value.some((s) => s.NumeroFactura.includes(filter.value))
          );
        } else if (filter.key == 'DocumentosTransporte') {
          return (
            value != null &&
            Array.isArray(value) &&
            value.some((s) => s.CorrelativoDocumento.includes(filter.value))
          );
        } else if (
          ['Notas', 'Eventos', 'Alertas', 'Incidencias', 'Casos'].includes(
            filter.key
          )
        ) {
          return (
            value != null &&
            Array.isArray(value) &&
            value.length === parseInt(filter.value, 10)
          );
        } else {
          return (
            value != null &&
            value.toString().toLowerCase().includes(filter.value.toLowerCase())
          );
        }
      });
    });
    console.log(this.newArrayGestion);
  }
}

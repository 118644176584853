<!-- Toolbar -->
<app-bar-menu-principal></app-bar-menu-principal>

<div class="container contenedorVesta">
  <router-outlet></router-outlet>

</div>
<!--<div class="grid ">
  <div class="row">
    <div class="cell">
    </div>
  </div>
</div>-->

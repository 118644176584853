import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorteDiarioModel } from '../corte-diario.models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'modal-bitacora',
  templateUrl: './modal-bitacora.component.html',
  styleUrls: ['./modal-bitacora.component.css']
})
export class ModalBitacoraComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        gestionSelected: CorteDiarioModel.GetGestionesAbiertasXRutas,
        usuarioid: string
      }
  ) { }
  public gestion: CorteDiarioModel.GetGestionesAbiertasXRutas;
  public completeUrl: SafeResourceUrl;

  ngOnInit(): void {
    this.gestion = this.data.gestionSelected;
    let url = `https://modelologisticoderedwebapi.azurewebsites.net/reportebitacora/index/${this.gestion.NumeroGestion}/${this.data.usuarioid}`;
    this.completeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

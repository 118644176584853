<!--<h2 mat-dialog-title>Install Angular</h2>-->
<div mat-dialog-title class="rowFlexNoWrap">
  <button
    mat-button
    mat-dialog-close
    class="close-button"
    style="margin-left: auto"
  >
    <img
      src="../../../assets/icons/cerrar.png"
      alt="Alternate Text"
      style="height: 30px; width: 30px"
    />
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div class="rowFlexNoWrap" style="justify-content: space-between">
    <!--<div style="width: 40%" class="rowFlexNoWrap ">
      <ng-select class="cellGrid" [items]="listAduanas" bindLabel="Nombre" bindValue="Id" placeholder="Seleccione una Aduana" [(ngModel)]="aduanaIdSeleccionada" style="width: 100%">
      </ng-select>
      <div class="cellGrid">
        <button class="buttonTransparente" (click)="PostCambiarFrontera()">
          <img src="../../../assets/icons/cambiar.png" alt="Alternate Text" title="Cambiar Frontera" style="width: 34px; height: 26px" />
        </button>
      </div>
    </div>-->
    <button
      class="button"
      mat-button
      style="margin-left: auto"
      (click)="PostCambiarFrontera()"
    >
      <img src="../../../assets/icons/graph.png" alt="Alternate Text" />
      Graficar
    </button>
  </div>

  <!--************GRID***********-->

  <div class="SectionGrids">
    <div style="">
      <!--************Encabezado***********-->

      <div
        class="flexRowNoWrap EncabezadoGridR1"
        style="justify-content: space-between; flex-wrap: nowrap"
      >
        <div class="cellListP" style="width: 40px; min-width: 40px">
          <div class="cellListE1">
            &nbsp;
            <!--<input type="checkbox" name="name" value="" />-->
          </div>
        </div>

        <div class="cellListP" style="min-width: 300px">
          <div class="cellListE1"><span>Ruta</span></div>
        </div>

        <!--<div class="cellListP " style="min-width: 80px; width: 80px; ">
          <div class="cellListE1 "><span>Gestion</span></div>
        </div>

        <div class="cellListP " style="min-width: 80px; width: 80px; ">
          <div class="cellListE1 "><span>SKU</span></div>
        </div>-->

        <div class="cellListP">
          <div class="cellListE1"><span>Aduana</span></div>
        </div>

        <div class="cellListP" style="min-width: 300px">
          <div class="cellListE1"><span>Sitio Referencia</span></div>
        </div>
      </div>
    </div>
    <!--************Body grids***********-->
    <div>
      <div class="subsection" style="min-height: 220px">
        <div *ngFor="let item of listrutasXUsuario; let i = index">
          <div title="{{ item.NoGestiones + ' Gestiones' }}">
            <div
              class="flexRowNoWrap rowGrid"
              [ngClass]="{ 'disabled-inputs': item.NoGestiones == 0 }"
            >
              <div class="cellListP" style="width: 40px">
                <div class="cellListE1">
                  <input
                    type="checkbox"
                    name="name"
                    value=""
                    [(ngModel)]="item.Selected"
                  />
                </div>
              </div>

              <div class="cellListP" style="min-width: 300px">
                <div class="cellListE1">
                  <span>{{ item.NombreRuta }}</span>
                </div>
              </div>

              <!--<div class="cellListP " style="min-width: 80px; width: 80px; ">
      <div class="cellListE1 "><span>{{item.Gestion}}</span></div>
    </div>

    <div class="cellListP " style="min-width: 80px; width: 80px; ">
      <div class="cellListE1 "><span>{{item.SKU}}</span></div>
    </div>-->

              <div class="cellListP" style="">
                <div class="cellListE1">
                  <span>{{ item.AduanaEnSitio }}</span>
                </div>
              </div>

              <div class="cellListP" style="display: unset; min-width: 300px">
                <div
                  class="input-large input"
                  style="margin-right: 10px; height: 34px"
                >
                  <ng-select
                    class="cellGrid"
                    [items]="item.listSitios"
                    bindLabel="Sitio.Nombre"
                    bindValue="Sitio.Id"
                    [(ngModel)]="item.SitioReferenciaId"
                    (change)="EventChangeFrontera(item)"
                    placeholder="Seleccionar Sitio"
                    style="width: 100%"
                    [disabled]="true"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

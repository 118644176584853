<div mat-dialog-title class="rowFlexNoWrap">

  <div class="rowText">
    Bitacora
  </div>

  <button mat-button mat-dialog-close class="close-button" style="margin-left:auto">
    <img src="../../../assets/icons/cerrar.png" alt="Alternate Text" style="height:30px; width: 30px" />
  </button>

</div>

<mat-dialog-content class="mat-typography" style="height: 92%; max-height: max-content !important; overflow: hidden !important; ">

  <iframe [src]="completeUrl" style=" width: 100%; height: 100%" frameborder="0"></iframe>

</mat-dialog-content>

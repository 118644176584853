import { Injectable } from '@angular/core';
import { CallHttpService, DialogVestaService } from '@vesta/vesta';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CorteDiarioModel } from '../corte-diario/corte-diario.models';
import { CostoProyectadoVsCostoRealModels } from '../costo-proyectado-vs-costo-real/costo-proyectado-vs-costo-real.models';
import { AnalisisImpactoModels } from '../models/analisis-impacto.models';

@Injectable({
  providedIn: 'root',
})
export class CallsAnalisisService {
  constructor(
    private callHttp: CallHttpService,
    private dialogService: DialogVestaService
  ) {}

  /**Obtener las rutas.*/
  GetRutas() {
    let promise = new Promise<Array<CostoProyectadoVsCostoRealModels.Rutas>>(
      (resolve, reject) => {
        this.callHttp
          .httpGet<Array<CostoProyectadoVsCostoRealModels.Rutas>>(
            `${environment.webService.analisisVesta}/Mants/Ruta/Home/Index`
          )
          .subscribe(
            (response) => {
              resolve(this.orderBy(response, 'Descripcion'));
            },
            (error: any) => {
              this.dialogService.NotificacionAlert(error);
              reject(error);
            }
          );
      }
    );

    return promise;
  }

  /**
   * Obtener todos los skus por categorías producto
   * @param filtro
   */
  GetSkusByCategoriasProducto(filtro: any) {
    let promise = new Promise<Array<AnalisisImpactoModels.SkuVm>>(
      (resolve, reject) => {
        this.callHttp
          .httpPost<Array<AnalisisImpactoModels.SkuVm>>(
            `${environment.webService.analisisVesta}/Mants/CategoriaProducto/Home/DetailsSkuIdByCategoriavesta`,
            filtro
          )
          .subscribe(
            (response) => {
              resolve(this.orderBy(response, 'Descripcion'));
            },
            (error: any) => {
              this.dialogService.NotificacionAlert(error);
              reject(error);
            }
          );
      }
    );

    return promise;
  }

  /**Obtener las categorías que agrupan sku */
  GetCategoriasProducto() {
    let promise = new Promise<
      Array<AnalisisImpactoModels.CategoriasAndUnidadMedidaVm>
    >((resolve, reject) => {
      this.callHttp
        .httpGet<Array<AnalisisImpactoModels.CategoriasAndUnidadMedidaVm>>(
          `${environment.webService.analisisVesta}/Mants/CategoriaProducto/Home/IndexByPersonas`
        )
        .subscribe(
          (response) => {
            resolve(this.orderBy(response, 'Descripcion'));
          },
          (error: any) => {
            this.dialogService.NotificacionAlert(error);
            reject(error);
          }
        );
    });

    return promise;
  }

  /**Obtener las unidades de medida */
  GetUnidadesMedida() {
    let promise = new Promise<
      Array<AnalisisImpactoModels.CategoriasAndUnidadMedidaVm>
    >((resolve, reject) => {
      this.callHttp
        .httpGet<Array<AnalisisImpactoModels.CategoriasAndUnidadMedidaVm>>(
          `${environment.webService.analisisVesta}/Mants/UnidadesDeMedida/UnidadDePeso/Home/Index`
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error: any) => {
            this.dialogService.NotificacionAlert(error);
            reject(error);
          }
        );
    });

    return promise;
  }

  /**
   * Obtener de una ruta en específico los costos reales y diferencia (es decir, costo real menos costo proyectado)
   * agrupados por semanas.
   * @param filtro
   */
  GetCostosRealVsDiferencia(filtro: any) {
    return this.callHttp
      .httpPost<{ SemanAndRuta: Array<AnalisisImpactoModels.ISemanaAndRuta> }>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/GetCostosRelaVsDiferenciaXRangoDeFecha`,
        filtro
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of(null);
        })
      );
  }

  /**
   * Obtener de una ruta en específico los costos reales y proyectados de acuerdo a un rango de fechas.
   * @param filtro
   */
  GetCostos(filtro: any) {
    return this.callHttp
      .httpPost<AnalisisImpactoModels.Costos>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/GetCostosPorRangoDeFechaAndRutaId`,
        filtro
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of(null);
        })
      );
  }
  GetCostosPorSkus(filtro: any) {
    return this.callHttp
      .httpPost<{ MesAndRuta: Array<AnalisisImpactoModels.CostosPorSkus> }>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/GetCostosRelaVsDiferenciaXRangoDeFechaByListaSku`,
        filtro
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of(null);
        })
      );
  }
  GetCostosPorCategoriasProductoPorSemana(filtro: any) {
    return this.callHttp
      .httpPost<any>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/GetCostosRelaVsDiferenciaXRangoDeFechaByListaCategoriaSemana`,
        filtro
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of(null);
        })
      );
  }
  GetCostosPorSkusPorSemana(filtro: any) {
    return this.callHttp
      .httpPost<{ MesAndRuta: Array<any> }>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/GetCostosRelaVsDiferenciaXRangoDeFechaByListaSkuSemana`,
        filtro
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of(null);
        })
      );
  }

  /**
   * Obtener los costos de una ruta en específico de acuerdo a un rango de fechas y por categorías de producto.
   * @param filtro
   */
  GetCostosPorCategoriasProducto(filtro: any) {
    return this.callHttp
      .httpPost<{
        MesAndRuta: Array<AnalisisImpactoModels.CostosPorCategoriaProducto>;
      }>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/GetCostosRelaVsDiferenciaXRangoDeFechaByListaCategoria`,
        filtro
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of(null);
        })
      );
  }

  orderBy(value: Array<any>, propName: string) {
    return value.sort((a, b) => {
      if (a[propName].toUpperCase() < b[propName].toUpperCase()) {
        return -1;
      } else if (a[propName].toUpperCase() > b[propName].toUpperCase()) {
        return 1;
      } else return 0;
    });
  }
  /*Obtener el gráfico de tiempo promedio de traslado por ruta*/
  GetNivelServicioTransportista(object: Object) {
    return this.callHttp
      .httpPost<any>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/GetTiempoPromedioTrasladoxRutaXFecha`,
        object
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of([]);
        })
      );
  }
  GetNivelServicioTransportistaMes(object: Object) {
    return this.callHttp
      .httpPost<any>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/GetTiempoPromedioTrasladoxRutaXFechaMes`,
        object
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of([]);
        })
      );
  }

  /*Obtener holgura total ruta transporte*/
  GetHolguraTotalRutaTransporte(object: Object) {
    return this.callHttp
      .httpPost<any>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/HolguraTotalRutaTransporte`,
        object
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of([]);
        })
      );
  }

  /*Obtener consumo promedio tiempo libre contenedor*/
  GetConsumoPromedioTiempoLibreContenedor(object: Object) {
    return this.callHttp
      .httpPost<any>(
        `${environment.webService.analisisVesta}/Mants/GrupoDeRutaXSemana/Home/ConsumoPromedioTiempoLibreContenedor`,
        object
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of([]);
        })
      );
  }

  /*HomeCreateReporteGraficos*/
  HomeCreateReporteGraficos(object: Object) {
    return this.callHttp
      .httpPost<any>(
        `${environment.webService.analisisVesta}/Repos/ConsumoGraficos/Home/Create`,
        object
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          this.dialogService.NotificacionAlert(error);
          return of([]);
        })
      );
  }

  /*Obtener Gestiones por Rutas*/
  GetGestionesAbiertasXRutas(object: any) {
    console.log('object', object);
    let promise = new Promise<
      Array<CorteDiarioModel.GetGestionesAbiertasXRutas>
    >((resolve, reject) => {
      this.callHttp
        .httpPost<Array<CorteDiarioModel.GetGestionesAbiertasXRutas>>(
          `${environment.webService.analisisVesta}/Mants/RutaXGestion/Home/GetGestionesAbiertasXRutasV2`,
          object
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        );
    });

    return promise;
  }

  GetRutasByUsuarioPId(usuarioPId: string) {
    let query = {
      UsuarioPersonaId: usuarioPId,
    };

    // URL específica para este método en producción
    const productionUrl =
      'https://analisisderedapi.vesta-accelerate.com/api/RutaCrudApi/GetRutasByUsuarioId';

    let promise = new Promise<
      CorteDiarioModel.AppResultDto<Array<CorteDiarioModel.RutasByUsuario>>
    >((resolve, reject) => {
      this.callHttp
        .httpPost<
          CorteDiarioModel.AppResultDto<Array<CorteDiarioModel.RutasByUsuario>>
        >(productionUrl, query)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        );
    });

    return promise;
  }

  GetPlantillasIndex() {
    let promise = new Promise<Array<CorteDiarioModel.PlantillasIndex>>(
      (resolve, reject) => {
        this.callHttp
          .httpGet<Array<CorteDiarioModel.PlantillasIndex>>(
            `${environment.webService.analisisVesta}/Mants/Template/Home/Index`
          )
          .subscribe(
            (response) => {
              resolve(response);
            },
            (error: any) => {
              reject(error);
            }
          );
      }
    );

    return promise;
  }

  PostHistoricoXPlantilla(object: any) {
    let promise = new Promise<
      CorteDiarioModel.AppResultDto<CorteDiarioModel.PlantillasIndex>
    >((resolve, reject) => {
      this.callHttp
        .httpPost<
          CorteDiarioModel.AppResultDto<CorteDiarioModel.PlantillasIndex>
        >(
          `${environment.webService.analisisVesta}/Mants/TemplateHistoricoDias/Home/Create`,
          object
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        );
    });

    return promise;
  }
  GetHistoricoDiasByPlantilla(templateId: string) {
    let promise = new Promise<Array<CorteDiarioModel.HistoricoTemplate>>(
      (resolve, reject) => {
        this.callHttp
          .httpGet<Array<CorteDiarioModel.HistoricoTemplate>>(
            `${environment.webService.analisisVesta}/Mants/TemplateHistoricoDias/Home/ByTemplateId?TemplateId=` +
              templateId
          )
          .subscribe(
            (response) => {
              resolve(response);
            },
            (error: any) => {
              reject(error);
            }
          );
      }
    );

    return promise;
  }

  PostPlantillasCreate(object: any) {
    let promise = new Promise<
      CorteDiarioModel.AppResultDto<CorteDiarioModel.PlantillasIndex>
    >((resolve, reject) => {
      this.callHttp
        .httpPost<
          CorteDiarioModel.AppResultDto<CorteDiarioModel.PlantillasIndex>
        >(
          `${environment.webService.analisisVesta}/Mants/Template/Home/Create`,
          object
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        );
    });

    return promise;
  }

  PostPlantillasUpdate(object: any) {
    let promise = new Promise<
      CorteDiarioModel.AppResultDto<CorteDiarioModel.PlantillasIndex>
    >((resolve, reject) => {
      this.callHttp
        .httpPost<
          CorteDiarioModel.AppResultDto<CorteDiarioModel.PlantillasIndex>
        >(
          `${environment.webService.analisisVesta}/Mants/Template/Home/Update`,
          object
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        );
    });

    return promise;
  }

  PostPlantillasFav(object: any) {
    let promise = new Promise<CorteDiarioModel.PlantillasIndex>(
      (resolve, reject) => {
        this.callHttp
          .httpPost<CorteDiarioModel.PlantillasIndex>(
            `${environment.webService.analisisVesta}/Mants/Template/Home/UpdateTemplateFavorita`,
            object
          )
          .subscribe(
            (response) => {
              resolve(response);
            },
            (error: any) => {
              reject(error);
            }
          );
      }
    );

    return promise;
  }

  PostPlantillaDelete(object: any) {
    let promise = new Promise<CorteDiarioModel.PlantillasIndex>(
      (resolve, reject) => {
        this.callHttp
          .httpPost<CorteDiarioModel.PlantillasIndex>(
            `${environment.webService.analisisVesta}/Mants/Template/Home/Delete`,
            object
          )
          .subscribe(
            (response) => {
              resolve(response);
            },
            (error: any) => {
              reject(error);
            }
          );
      }
    );

    return promise;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBarMenuPrincipalComponent } from '../components/app-bar-menu-principal/app-bar-menu-principal.component';
import { AppRoutingModule } from '../../app/app-routing.module';



@NgModule({
  declarations: [
    AppBarMenuPrincipalComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule
  ],
  exports: [AppBarMenuPrincipalComponent]
})
export class ModuloCoreModule { }

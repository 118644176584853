import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-bar-menu-principal',
  templateUrl: './app-bar-menu-principal.component.html',
  styleUrls: ['./app-bar-menu-principal.component.css']
})
export class AppBarMenuPrincipalComponent implements OnInit, AfterContentInit {

  paths: any[] = [
    {
      dropdownName: 'Ruta',
      childrensPaths: [
        {
          Name: 'Tendencia y Estacionalidad',
          childsOfChilds: [
            {
              Name: 'Costos no previstas',
              routerLink: ['/mainrutatendenciaandestacionalidad/home/Index', { outlets: { main: 'costossnoprevistas' } }]
            },
            {
              Name: 'Costos logísticas totales',
              routerLink: ['/mainrutatendenciaandestacionalidad/home/Index', { outlets: { main: 'costoslogisticastotales' } }]
            }
          ]
        },
        {
          Name: 'Comparativa',
          childsOfChilds: [
            {
              Name: 'Costo proyectada vs Costo real',
              routerLink: ['/mainrutacomparativa/home/Index', { outlets: { main: 'costoproyectadavscostoreal' } }]
            },
            {
              Name: 'Volumen y pesos subutilizados',
              routerLink: ['/mainrutacomparativa/home/Index', { outlets: { main: 'volumenandpesosubutlizado' } }]
            },
            {
              Name: 'Oportunidad mejora ocupación',
              routerLink: ['/mainrutacomparativa/home/Index', { outlets: { main: 'oportunidadmejoraocupacion' } }]
            },
            {
              Name: 'Oportunidad ocupación',
              routerLink: ['/mainrutacomparativa/home/Index', { outlets: { main: 'oportunidadocupacion' } }]
            },
            {
              Name: 'Costo real vs diferencia',
              routerLink: ['/mainrutacomparativa/home/Index', { outlets: { main: 'costorealvsdiferencia' } }]
            }
          ]
        },
        {
          Name: 'Composición',
          childsOfChilds: [
            {
              Name: 'Costo logística promedio',
              routerLink: ['/mainrutacomposicion/home/Index', { outlets: { main: 'costologisticapromedio' } }]
            },
            {
              Name: 'Costo total ruta',
              routerLink: ['/mainrutacomposicion/home/Index', { outlets: { main: 'costototalruta' } }]
            }
          ]
        }
      ]
    },
    {
      dropdownName: 'Producto',
      childrensPaths: [
        {
          Name: 'Tendencia y Estacionalidad',
          childsOfChilds: [
            {
              Name: 'Costo total por categoría producto',
              routerLink: ['/mainproductotendenciaandestacionalidad/home/Index', { outlets: { main: 'costototalporcategoriaproducto' } }]
            },
            {
              Name: 'Costo total por producto',
              routerLink: ['/mainproductotendenciaandestacionalidad/home/Index', { outlets: { main: 'costototalporproducto' } }]
            }
          ]
        },
        {
          Name: 'Comparativa',
          childsOfChilds: [
            {
              Name: 'Costo real vs Costo proyectado por categoria producto',
              routerLink: ['/mainproductocomparativa/home/Index', { outlets: { main: 'costorealvscostoproyectadoporcategoriaproducto' } }]
            },
            {
              Name: 'Costo total mensual producto',
              routerLink: ['/mainproductocomparativa/home/Index', { outlets: { main: 'costototalmensualproducto' } }]
            }
          ]
        },
        {
          Name: 'Composición',
          childsOfChilds: [
            {
              Name: 'Promedio mensual costo',
              routerLink: ['/mainproductocomposicion/home/Index', { outlets: { main: 'promediomensualcosto' } }]
            }
          ]
        }
      ]
    },
    {
      dropdownName: 'Nivel de Servicio',
      childrensPaths: [
        {
          Name: 'Tendencia y Estacionalidad', link: 'mainserviciotendenciaandestacionalidad/home/Index',
          childsOfChilds: [
            {
              Name: 'Tiempo promedio traslado',
              routerLink: ['/mainserviciotendenciaandestacionalidad/home/Index', { outlets: { main: 'tiempopromediotraslado' } }]
            }
          ]
        },
        {
          Name: 'Comparativa', link: 'mainserviciocomparativa/home/Index',
          childsOfChilds: [
            {
              Name: 'Nivel de servicio de transportista',
              routerLink: ['/mainserviciocomparativa/home/Index', { outlets: { main: 'nivelserviciotransportistas' } }]
            },
            {
              Name: 'Holgura total',
              routerLink: ['/mainserviciocomparativa/home/Index', { outlets: { main: 'holguratotal' } }]
            },
            {
              Name: 'Consumo promedio tiempo libre',
              routerLink: ['/mainserviciocomparativa/home/Index', { outlets: { main: 'consumopromediodetiempolibre' } }]
            },
            {
              Name: 'Tiempo libre y demora promedio',
              routerLink: ['/mainserviciocomparativa/home/Index', { outlets: { main: 'tiempolibreanddemorapromedio' } }]
            },
            {
              Name: 'Comparación de tiempo libre',
              routerLink: ['/mainserviciocomparativa/home/Index', { outlets: { main: 'comparaciondetiempolibre' } }]
            }
          ]
        },
        {
          Name: 'Composición', link: 'mainserviciocomposicion/home/Index',
          childsOfChilds: [
            {
              Name: 'Tiempo total de gestión',
              routerLink: ['/mainserviciocomposicion/home/Index', { outlets: { main: 'tiempototaldegestion' } }]
            },
            {
              Name: 'Tiempo meta segmento por ruta',
              routerLink: ['/mainserviciocomposicion/home/Index', { outlets: { main: 'tiempometasegmentoporruta' } }]
            }
          ]

        }
      ]
    }
  ]

  constructor(private router: Router) {
  }
  ngAfterContentInit(): void {
    this.paths.forEach(e => {
      e.childrensPaths.forEach(f => {
        f.childsOfChilds.forEach(c => {
          const route = this.router.createUrlTree(c.routerLink);
          const url = this.router.serializeUrl(route);
          c.href = url
        })
      })
    })
  }

  ngOnInit(): void {
  }

  user: any = { Name: 'Mario Salgado', Id: '6bc253c4-480f-4fc8-bad4-1ed8bb623036' };
  _navBarService: any = { Src: 'alarm-006' };

  NavOpen(): void {
    //this.navBarService.OpenCloseNav();
  }

  Logout(): void {

  }

}

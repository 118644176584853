import { Injectable } from '@angular/core';
import { DialogService, NotifyService } from 'ng-metro4';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogVestaService {

  constructor(private dialogService: DialogService, 
    private notifyService: NotifyService) { }


    private showDialog:any;
    private showConfirm: any;

    ShowDialog(mensaje: string, title?: string ) {

      if(title) this.dialogService.alert(title, mensaje,'border border-size-2');
      else this.dialogService.info(mensaje,{clsBox: 'border-2px'});
      //this.dialogService.alert(title == undefined ? '': title, mensaje)
      // this.showDialog = this.dialogService.create({
      //   title: title,
      //   content: `<div style="min-width:300px;max-width: 600px;width:auto;">${mensaje}</div>`,
      //   overlay: true,
      //   overlayColor: 'op-dark',
      //   overlayClickClose: true,
      //   actions: [
      //     {
      //       onclick:  () => {
      //         this.dialogService.close(this.showDialog);
      //       },
      //       cls: 'success',
      //       caption: 'Ok'
      //     }
      //   ]
      // })
    }

    ShowConfirm(mensaje: string, title: string): Observable<boolean>{
      let subject: Subject<boolean> = new Subject();
      
      this.dialogService.alert(title, mensaje, 'border border-size-2', 'Ok', 'sucess');
      // this.showConfirm = this.dialogService.create({
      //   title: title,
      //   content: `<div style="min-width:300px;max-width: 600px;width:auto;">${mensaje}</div>`,
      //   overlay: true,
      //   overlayColor: 'op-dark',
      //   overlayClickClose: true,
      //   actions: [
      //     {
      //       onclick:  () => {
      //         this.dialogService.close(this.showConfirm);
      //         subject.next(true);
      //       },
      //       cls: 'success',
      //       caption: 'Ok'
      //     },
      //     {
      //       onclick:  () => {
      //         this.dialogService.close(this.showConfirm);
      //         subject.next(false);
      //       },
      //       cls: 'alert',
      //       caption: 'Cancel'
      //     },

      //   ]
      // })

      return subject;
    }

    ShowConfirmYesNo(mensaje: string, title: string): Observable<boolean> {
      return this.dialogService.confirm(title, mensaje,'Si', 'No','border border-size-2','sucess','alert');;
    }

    NotificacionSuccess(mensaje: string, keepOpen: boolean = false): void {
      this.Notificacion(mensaje, 'success',keepOpen, 30000);
    }

    NotificacionAlert(mensaje: string, keepOpen: boolean = false): void {
      this.Notificacion(mensaje, 'alert',keepOpen, 30000);
    } 
    
    NotificacionWarning(mensaje: string, keepOpen: boolean = false): void {
      this.Notificacion(mensaje, 'warning',keepOpen, 30000);
    }

    NotificacionInfo(mensaje: string, keepOpen: boolean = false): void {
      this.Notificacion(mensaje, 'info',keepOpen, 30000);
    }



    private Notificacion(mensaje: string, type: string, keepOpen: boolean, timeout: number){
      this.notifyService.create(mensaje, undefined, {keepOpen: keepOpen, cls: type})
    }
}

<div mat-dialog-title class="rowFlexNoWrap">
  <div style="text-align: center; width: 100%;">
    ELIMINAR PLANTILLA
  </div>

  <!--<button mat-button mat-dialog-close class="close-button" style="margin-left:auto">
    <img src="../../../assets/icons/cerrar.png" alt="Alternate Text" style="height:30px; width: 30px" />
  </button>-->
</div>

<mat-dialog-content class="mat-typography">
  <div class="Container" style="height: 100%">
    <div class="modal-body cellGrid" style="height: 70px; text-align: center;">
      <h2>¿Desea eliminar esta plantilla?</h2>

    </div>
  </div>



</mat-dialog-content>
<mat-dialog-actions align="center" style=" height: auto; justify-content:space-around" class="flexRowNoWrap"> 
  <button type="button" mat-button [mat-dialog-close]="true" cdkFocusInitial mat-flat-button color="primary">Cancelar</button>
  <button type="button" mat-flat-button color="primary" (click)="PostDeletePLantilla()">Aceptar</button>
</mat-dialog-actions>

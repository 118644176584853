<!--<h2 mat-dialog-title>Install Angular</h2>-->
<div mat-dialog-title>
  <button mat-icon-button mat-dialog-close class="close-button" style="margin-left:auto">
    <img src="../../../assets/icons/cerrar.png" alt="Alternate Text" style="height:30px; width: 30px" />
  </button>
</div>

<mat-dialog-content class="mat-typography">

  <graph-by-analisis #graphanalisis (reload)="dataReload($event)"></graph-by-analisis>

</mat-dialog-content>
<!--<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions>-->

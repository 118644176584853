import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/mainrutacomparativa/home/Index/(main:costoproyectadavscostoreal)' },
  {
    path: 'mainrutatendenciaandestacionalidad', loadChildren: () => import('./main-ruta-tendencia-y-estacionalidad/main-ruta-tendencia-y-estacionalidad.module')
      .then(t => t.MainRutaTendenciaYEstacionalidadModule)
  },
  {
    path: 'mainrutacomparativa', loadChildren: () => import('./main-ruta-comparativa/main-ruta-comparativa.module')
      .then(t => t.MainRutaComparativaModule)
  },
  {
    path: 'mainrutacomposicion', loadChildren: () => import('./main-ruta-composicion/main-ruta-composicion.module')
      .then(t => t.MainRutaComposicionModule)
  },
  {
    path: 'mainproductotendenciaandestacionalidad', loadChildren: () => import('./main-producto-tendencia-y-estacionalidad/main-producto-tendencia-y-estacionalidad.module')
      .then(t => t.MainProductoTendenciaYEstacionalidadModule)
  },
  {
    path: 'mainproductocomparativa', loadChildren: () => import('./main-producto-comparativa/main-producto-comparativa.module')
      .then(t => t.MainProductoComparativaModule)
  },
  {
    path: 'mainproductocomposicion', loadChildren: () => import('./main-producto-composicion/main-producto-composicion.module')
      .then(t => t.MainProductoComposicionModule)
  },
  {
    path: 'mainserviciocomposicion', loadChildren: () => import('./main-servicio-composicion/main-servicio-composicion.module')
      .then(t => t.MainServicioComposicionModule)
  },
  {
    path: 'mainserviciocomparativa', loadChildren: () => import('./main-servicio-comparativa/main-servicio-comparativa.module')
      .then(t => t.MainServicioComparativaModule)
  },
  {
    path: 'mainserviciotendenciaandestacionalidad', loadChildren: () => import('./main-servicio-tendencia-y-estacionalidad/main-servicio-tendencia-y-estacionalidad.module')
      .then(t => t.MainServicioTendenciaYEstacionalidadModule)
  },
  {
    path: 'maincortediario', loadChildren: () => import('./corte-diario/corte-diario.module')
      .then(t => t.CorteDiarioModule)
  },
  {
    path: 'reports', children: [
      //Ruta-Comparativa
      { path: 'costoproyectadovscostoreal', loadChildren: () => import('./costo-proyectado-vs-costo-real/costo-proyectado-vs-costo-real.module').then(t => t.CostoProyectadoVsCostoRealModule) },
      { path: 'volumenandpesosubutlizado', loadChildren: () => import('./volumen-y-peso-sub-utilizado/volumen-y-peso-sub-utilizado.module').then(t => t.VolumenYPesoSubUtilizadoModule) },
      { path: 'oportunidadmejoraocupacion', loadChildren: () => import('./oportunidad-mejora-ocupacion/oportunidad-mejora-ocupacion.module').then(t => t.OportunidadMejoraOcupacionModule) },
      { path: 'oportunidadocupacion', loadChildren: () => import('./oportunidad-ocupacion/oportunidad-ocupacion.module').then(t => t.OportunidadOcupacionModule) },
      { path: 'costorealvsdiferencia', loadChildren: () => import('./costo-real-vs-diferencia/costo-real-vs-diferencia.module').then(t => t.CostoRealVsDiferenciaModule) },
      //Ruta-Tendencia y estacionalidad
      { path: 'costosnoprevistos', loadChildren: () => import('./costos-no-previstos/costos-no-previstos.module').then(t => t.CostosNoPrevistosModule) },
      { path: 'costoslogisticostotales', loadChildren: () => import('./costos-logisticos-totales/costos-logisticos-totales.module').then(t => t.CostosLogisticosTotalesModule) },
      //Ruta-Composición
      { path: 'costoslogisticospromedio', loadChildren: () => import('./costos-logisticos-promedio/costos-logisticos-promedio.module').then(t => t.CostosLogisticosPromedioModule) },
      { path: 'costototalruta', loadChildren: () => import('./costo-total-ruta/costo-total-ruta.module').then(t => t.CostoTotalRutaModule) },
      //Producto-Comparativa
      { path: 'costosproductosporrutaycategoria', loadChildren: () => import('./costos-productos-ruta-categoria/costos-productos-ruta-categoria.module').then(t => t.CostosProductosRutaCategoriaModule) },
      { path: 'costosproductosporrutaysku', loadChildren: () => import('./costos-productos-ruta-sku/costos-productos-ruta-sku.module').then(t => t.CostosProductosRutaSkuModule) },
      { path: 'costorealvscostoproyectadoporcategoriaproducto', loadChildren: () => import('./costo-real-vs-proyectado-categoria-producto/costo-real-vs-proyectado-categoria-producto.module').then(t => t.CostoRealVsProyectadoCategoriaProductoModule) },
      { path: 'costorealvscostoproyectadoporskuproducto', loadChildren: () => import('./costo-real-vs-proyectado-sku-producto/costo-real-vs-proyectado-sku-producto.module').then(t => t.CostoRealVsProyectadoSkuProductoModule) },
      { path: 'costototalmensualproducto', loadChildren: () => import('./costo-total-mensual-producto/costo-total-mensual-producto.module').then(t => t.CostoTotalMensualProductoModule) },
      //Producto-Tendencia y estacionalidad
      { path: 'costototalporcategoriaproducto', loadChildren: () => import('./costo-total-categoria-producto/costo-total-categoria-producto.module').then(t => t.CostoTotalCategoriaProductoModule) },
      { path: 'costototalporproducto', loadChildren: () => import('./costo-total-producto/costo-total-producto.module').then(t => t.CostoTotalProductoModule) },
      //Producto-Composición
      { path: 'promediomensualcosto', loadChildren: () => import('./promedio-mensual-costo/promedio-mensual-costo.module').then(t => t.PromedioMensualCostoModule) },
      //Nivel de servicio-Comparativa
      { path: 'nivelserviciotransportistas', loadChildren: () => import('./nivel-servicio-transportistas/nivel-servicio-transportistas.module').then(t => t.NivelServicioTransportistasModule) },
      { path: 'holguratotal', loadChildren: () => import('./holgura-total/holgura-total.module').then(t => t.HolguraTotalModule) },
      { path: 'consumopromediodetiempolibre', loadChildren: () => import('./consumo-promedio-de-tiempo-libre/consumo-promedio-de-tiempo-libre.module').then(t => t.ConsumoPromedioDeTiempoLibreModule) },
      { path: 'tiempolibreanddemorapromedio', loadChildren: () => import('./tiempo-libre-y-demora-promedio/tiempo-libre-y-demora-promedio.module').then(t => t.TiempoLibreYDemoraPromedioModule) },
      { path: 'costossobreestadia', loadChildren: () => import('./costos-sobre-estadia/costos-sobre-estadia.module').then(t => t.CostosSobreEstadiaModule) },
      { path: 'comparaciondetiempolibre', loadChildren: () => import('./comparacion-de-tiempo-libre/comparacion-de-tiempo-libre.module').then(t => t.ComparacionDeTiempoLibrePorSitioModule) },
      //Nivel de servicio-Tendencia y estacionalidad
      { path: 'tiempopromediotraslado', loadChildren: () => import('./tiempo-promedio-de-traslado/tiempo-promedio-de-traslado.module').then(t => t.TiempoPromedioDeTrasladoModule) },
      //Nivel de servicio-Composición
      { path: 'tiempototaldegestion', loadChildren: () => import('./tiempo-total-de-gestion/tiempo-total-de-gestion.module').then(t => t.TiempoTotalDeGestionModule) },
      { path: 'tiempometasegmentoporruta', loadChildren: () => import('./tiempo-meta-segmento-ruta-transporte/tiempo-meta-segmento-ruta-transporte.module').then(t => t.TiempoMetaSegmentoRutaTransporteModule) }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { AfterContentInit, Inject } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Enumerable from 'linq';
import { CallHttpService, DialogVestaService } from '@vesta/vesta';
import { CallsAnalisisService } from '../../services/calls-analisis.service';
import { CorteDiarioModel } from '../corte-diario.models';
import { GridRutasComponent } from '../grid-rutas/grid-rutas.component';
import { ModalCambiarFronteraComponent } from '../modal-cambiar-frontera/modal-cambiar-frontera.component';

@Component({
  selector: 'modal-delete-template',
  templateUrl: './modal-delete-template.component.html',
  styleUrls: ['./modal-delete-template.component.css']
})
export class ModalDeleteTemplateComponent implements OnInit {

  constructor(private serviceanalisis: CallsAnalisisService, private dialogService: DialogVestaService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        plantillaSelected: CorteDiarioModel.PlantillasIndex,
        UsuarioId: string,
      }
  ) { }

  public UsuarioId: string = '';
  public Reload: boolean = false;

  ngOnInit(): void {

  }

  PostDeletePLantilla() {
    let query = {
      "templateId": this.data.plantillaSelected.Id,
      "modifiedBy": this.data.UsuarioId
    }
    this.serviceanalisis.PostPlantillaDelete(query)
      .then(result => {
        if (result) {         

          this.dialogService.NotificacionSuccess('Plantilla Eliminada Correctamente');
          this.Reload = true;
          this.CerrarModal();
        }
      })
      .catch(e => {
        this.dialogService.NotificacionAlert(e);

      })
  }



  CerrarModal() {
    this.dialog.closeAll();
  }


}

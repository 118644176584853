import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Pipe({
  name: 'commaDecimal'
})
export class CommaDecimalPipe implements PipeTransform {
  transform(value: number): string {
    if ((value != null) && value != undefined) {
      const stringValue = parseFloat(value.toFixed(1)).toString();
      //const truncadoPorDiez = Math.trunc(value * 10);
      //const stringValue =(truncadoPorDiez / 10).toString();
      const [integerPart, decimalPart] = stringValue.split('.');
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      //return `${formattedIntegerPart}.${decimalPart}`;
      const formattedDecimalPart = decimalPart ? `.${decimalPart}` : '';
      return `${formattedIntegerPart}${formattedDecimalPart}`;
    }
    return ''
  }
}

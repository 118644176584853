import { AfterViewInit, ChangeDetectorRef, Inject } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Enumerable from 'linq';
import { CorteDiarioModel } from '../corte-diario.models';
import { GraphByAnalisisComponent } from '../graph-by-analisis/graph-by-analisis.component';
import { CallHttpService, DialogVestaService } from '@vesta/vesta';

@Component({
  selector: 'modal-graph-comparacion',
  templateUrl: './modal-graph-comparacion.component.html',
  styleUrls: ['./modal-graph-comparacion.component.css']
})
export class ModalGraphComparacionComponent implements OnInit,AfterViewInit {

  constructor(private cdr: ChangeDetectorRef, public dialog: MatDialog, private dialogService: DialogVestaService,
    @Inject(MAT_DIALOG_DATA) public data: {
      Allgestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>,
      gestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>,
      maxDias: number, mindias: number, isComparacion: boolean,
      listrutas: Array<CorteDiarioModel.listRutasGrid>,
      listRutasXUsuario: Array<CorteDiarioModel.RutasByUsuario>
      usuarioId: string
    }
  ) { }

  @ViewChild('GraphByAnalisis', { static: false }) GraphByAnalisisComponent: GraphByAnalisisComponent;
  @ViewChild('GraphByAnalisis2', { static: false }) GraphByAnalisisComponent2: GraphByAnalisisComponent;

  public FullScreenGraph1: boolean = false;
  public FullScreenGraph2: boolean = false;
  public listGestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> = [];
  public listRutas: Array<CorteDiarioModel.listRutasGrid> = [];

  ngOnInit(): void {
    this.listGestiones = this.data.gestiones;
    this.listRutas = this.data.listrutas;
  
  }

  ngAfterViewInit(): void {
    //this.GraphByAnalisisComponent.isComparacion = true;
    //this.GraphByAnalisisComponent2.isComparacion = true;
    //this.cdr.detectChanges();

    if (this.data.isComparacion) {
      //cuando es comparacion
      this.EventGraphcomparacion()
    } else {
      //cuando es excluido
      this.EventGraphExclusion()

    }
  }
  EventRegenerateGRaph1() {
   // if (this.FullScreenGraph1) {
      this.GraphByAnalisisComponent.EventRegenerateGraph();


  }
  EventRegenerateGRaph2() {
    this.GraphByAnalisisComponent2.EventRegenerateGraph();

  }
  EventGraphcomparacion() {
    console.log(this.listRutas)
    console.log(this.data.listRutasXUsuario)
    let rutasSelected = Enumerable.from(this.data.listRutasXUsuario).where(s => s.Selected == true).toArray();
    let rutasNoSelected = Enumerable.from(this.data.listRutasXUsuario).where(s => s.Selected == false).toArray();
    //para el componente 1
    let GestionesWithRutaSelected: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> = [];
    let GestionesWithRutaNoSelected: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> = [];
    this.listGestiones.forEach(gestion => {
      if (rutasSelected.some(s => s.Id == gestion.Ruta.Id)) {
        GestionesWithRutaSelected.push(gestion)

      } else if (rutasNoSelected.some(s => s.Id == gestion.Ruta.Id)) {

        GestionesWithRutaNoSelected.push(gestion)
      }

    })
    console.log(rutasSelected)
    console.log(rutasNoSelected)
    console.log(GestionesWithRutaSelected)
    console.log(GestionesWithRutaNoSelected)
    this.GraphByAnalisisComponent.isComparacionOrExclusion = true;
    this.GraphByAnalisisComponent2.isComparacionOrExclusion = true;
    this.cdr.detectChanges();
    console.log(GestionesWithRutaSelected)
    console.log(GestionesWithRutaNoSelected)

    this.GraphByAnalisisComponent.UsuarioId = this.data.usuarioId;
    this.GraphByAnalisisComponent.listGestiones = GestionesWithRutaSelected;

    this.GraphByAnalisisComponent2.listGestiones = GestionesWithRutaNoSelected;
    this.GraphByAnalisisComponent2.UsuarioId = this.data.usuarioId;

    this.GraphByAnalisisComponent.EventObtenerGestiones(GestionesWithRutaSelected,false, this.data.maxDias, this.data.mindias)
    this.GraphByAnalisisComponent2.EventObtenerGestiones(GestionesWithRutaNoSelected,false)


  }

  EventGraphExclusion() {
    //obtener el min y max de cada uno de los componentes.
    let min1 = this.data.mindias;
    let max1 = this.data.maxDias;
    console.log(min1)
    console.log(max1)
    let min2 = max1 +0.1;
    let max2 = Enumerable.from(this.data.Allgestiones).max(s => s.TiempoProceso);
    console.log(min2)
    console.log(max2)
    if (min2 <= max2) {
      this.GraphByAnalisisComponent.isComparacionOrExclusion = true;
      this.GraphByAnalisisComponent2.isComparacionOrExclusion = true;
      this.cdr.detectChanges();


      let gestiones = Enumerable.from(this.data.Allgestiones).where(s => s.TiempoProceso >= min1 && s.TiempoProceso <= max1).toArray();
      this.GraphByAnalisisComponent.listGestiones = gestiones;
      this.GraphByAnalisisComponent.UsuarioId = this.data.usuarioId;

      this.GraphByAnalisisComponent.EventObtenerGestiones(gestiones,false, max1, min1)
      console.log(gestiones)

      let gestiones2 = Enumerable.from(this.data.Allgestiones).where(s => s.TiempoProceso >= min2 && s.TiempoProceso <= max2).toArray();
      console.log(gestiones2)
      this.GraphByAnalisisComponent2.listGestiones = gestiones2;
      this.GraphByAnalisisComponent2.UsuarioId = this.data.usuarioId;

      this.GraphByAnalisisComponent2.EventObtenerGestiones(gestiones2, false, max2, min2)

    } else {
      console.log('err')
      this.dialogService.NotificacionAlert('Debe cambiar el rango de dias para poder excluir')
    }
  
  }

}

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AUTHENTICATION } from './inject-token/vesta-authentication.inject';
import { InterceptorSpinnerService } from './interceptors/interceptor-spinner.service';
import { VestaOptions } from './object/vesta-options.object';
import { CallHttpService } from './service/call-http.service';
import { DialogVestaService } from './service/dialog-vesta.service';


@NgModule({
  declarations: [    
  ],
  imports: [
    CommonModule, HttpClientModule
  ],
  exports: [  
  ],
  providers:[CallHttpService, 
    DialogVestaService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorSpinnerService, multi: true },
  ]
})
export class VestaModule { 
  static forRoot(settings: VestaOptions = {isAuthentication: false}): ModuleWithProviders<VestaModule>{
    return {
      ngModule: VestaModule,
      providers: [
        {provide: AUTHENTICATION, useValue: settings }
      ]
    };
  }
}

<div mat-dialog-title class="rowFlexNoWrap">
  <div>
    ACTUALIZAR FRONTERA
  </div>

  <button mat-button mat-dialog-close class="close-button" style="margin-left:auto">
    <img src="../../../assets/icons/cerrar.png" alt="Alternate Text" style="height:30px; width: 30px" />
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div class="Container">

    <div class="rowFlexNoWrap">
      <div class="cellW100"><strong>Aduana Actual:</strong></div>

    </div>
    <div class="rowFlexNoWrap">
      <div class="cellW100 input">
        <input type="text" name="name" value="" readonly />
      </div>

    </div>
    <div class="rowFlexNoWrap">
      <div class="cellW100"><strong>Nueva Aduana:</strong></div>

    </div>
    <div class="rowFlexNoWrap">
      <div class="cellW100">
        <div class="form-group">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Nueva Aduana</mat-label>
            <input type="text" placeholder="Buscar" matInput
                   formControlName="aduanas" [matAutocomplete]="auto">
            <button mat-icon-button matSuffix *ngIf="myform.get('aduanas').value" aria-label="Clear"
                    (click)="clear(myform.get('rutas'))">
              <span class="mif-cross-light" style="font-size: 0.5rem"></span>
            </button>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
              <!--<mat-option *ngFor="let option of RutasObs | async" [value]="option">
                {{option.Descripcion}}
              </mat-option>-->
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

    </div>


  </div>

  

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="button" mat-button [mat-dialog-close]="true" cdkFocusInitial style=" height: 100%;">
    <img src="../../../assets/icons/save.png" alt="Alternate Text" />
    ACTUALIZAR
  </button>
</mat-dialog-actions>

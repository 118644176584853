<!--<h2 mat-dialog-title>Install Angular</h2>-->
<div mat-dialog-title class="rowFlexNoWrap">
  <div>
    {{ titleModal }}
  </div>

  <button
    mat-button
    mat-dialog-close
    class="close-button"
    style="margin-left: auto"
  >
    <img
      src="../../../assets/icons/cerrar.png"
      alt="Alternate Text"
      style="height: 30px; width: 30px"
    />
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div class="Container">
    <div class="rowFlexNoWrap" style="align-items: center">
      <div class="cellW10"><strong>Nombre:</strong></div>
      <div
        class="cellW40 input-large input"
        style="margin-right: 10px; height: 34px"
      >
        <input
          type="text"
          name="name"
          value=""
          [(ngModel)]="createPlantilla.NombrePLantilla"
          style="height: 32px"
        />
      </div>
      <div class="cellW10"><strong>Dia Inicio:</strong></div>
      <div
        class="cellW15 input-large input"
        style="margin-right: 15px; height: 34px"
      >
        <input
          type="number"
          name="name"
          value=""
          style="height: 32px"
          [(ngModel)]="createPlantilla.DiaMin"
        />
      </div>
      <div class="cellW10"><strong>Dia Fin:</strong></div>
      <div
        class="cellW15 input-large input"
        style="margin-right: 10px; height: 34px"
      >
        <input
          type="number"
          name="name"
          value=""
          style="height: 32px"
          [(ngModel)]="createPlantilla.DiaMax"
        />
      </div>
    </div>
    <div class="rowFlexNoWrap" style="align-items: center">
      <div class="cellW10"><strong>Pública:</strong></div>
      <div style="margin-right: 15px">
        <input
          type="checkbox"
          name="name"
          value=""
          [(ngModel)]="createPlantilla.EsPublica"
        />
      </div>

      <div class="cellW10"><strong>Favorita:</strong></div>
      <div style="margin-right: 15px">
        <button
          class="buttonTransparente"
          *ngIf="!createPlantilla.Favorita"
          (click)="createPlantilla.Favorita = !createPlantilla.Favorita"
        >
          <img
            src="../../../assets/icons/star.png"
            alt="Alternate Text"
            style="height: 15px; width: 15px"
          />
        </button>
        <button
          class="buttonTransparente"
          *ngIf="createPlantilla.Favorita"
          (click)="createPlantilla.Favorita = !createPlantilla.Favorita"
        >
          <img
            src="../../../assets/icons/starYellow.png"
            alt="Alternate Text"
            style="height: 15px; width: 15px"
          />
        </button>
      </div>
    </div>

    <div
      class="rowFlexNoWrap"
      style="display: flex; align-items: center; gap: 20px"
    >
      <!-- Filtro de Rutas -->
      <div style="display: flex; align-items: center; gap: 10px; flex: 1">
        <div class="cellW10">
          <strong>Rutas:</strong>
        </div>
        <div class="input-large input" style="flex: 1">
          <input
            type="text"
            name="FiltrarRutas"
            value="FiltrarRutas"
            style="width: 100%; height: 34px; padding: 4px"
            placeholder="Presione Enter para filtrar..."
            (change)="FilterRutasInput()"
            [(ngModel)]="searchTermRuta"
          />
        </div>
      </div>

      <!-- Seleccionar Sitio -->
      <div style="display: flex; align-items: center; gap: 10px; flex: 1">
        <div class="cellW10">
          <strong>Sitio Referencia:</strong>
        </div>
        <div class="cellListP" style="flex: 1">
          <mat-form-field style="width: 100%">
            <mat-label>Seleccione un sitio</mat-label>
            <mat-select
              [(value)]="sitioSeleccionado"
              (selectionChange)="onSelectChange($event.value)"
            >
              <!-- Opción para borrar la selección -->
              <mat-option [value]="'null'">Borrar Toda la Selección</mat-option>

              <!-- Opciones normales -->

              <mat-option *ngFor="let sitio of listSitios" [value]="sitio.Id">
                {{ sitio.Nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!--************GRID***********-->
  <div style="margin-top: 1%"></div>
  <div class="SectionGrids">
    <div style="">
      <!--************Encabezado***********-->

      <div
        class="flexRowNoWrap EncabezadoGridR1"
        style="justify-content: space-between; flex-wrap: nowrap"
      >
        <div class="cellListP" style="width: 40px; min-width: 40px">
          <div class="cellListE1">
            <input
              type="checkbox"
              name="name"
              value=""
              [(ngModel)]="isCheckedAll"
              (change)="EventSelectAll()"
            />
          </div>
        </div>

        <div class="cellListP" style="min-width: 300px">
          <div class="cellListE1"><span>Ruta</span></div>
        </div>

        <!--<div class="cellListP " style="min-width: 80px; width: 80px; ">
          <div class="cellListE1 "><span>Gestion</span></div>
        </div>

        <div class="cellListP " style="min-width: 80px; width: 80px; ">
          <div class="cellListE1 "><span>SKU</span></div>
        </div>-->

        <div class="cellListP">
          <div class="cellListE1"><span>Aduana</span></div>
        </div>

        <div
          class="cellListP"
          style="display: unset; width: 280px; min-width: 280px"
        >
          <div class="cellListE1"><span>Sitio Referencia</span></div>
        </div>
      </div>
    </div>
    <!--************Body grids***********-->
    <div>
      <div class="subsection" style="min-height: 220px">
        <div *ngFor="let item of listrutasXUsuario; let i = index">
          <div class="flexRowNoWrap rowGrid">
            <div class="cellListP" style="width: 40px">
              <div class="cellListE1">
                <input
                  type="checkbox"
                  name="name"
                  value=""
                  [(ngModel)]="item.Selected"
                />
              </div>
            </div>

            <div class="cellListP" style="min-width: 300px">
              <div class="cellListE1">
                <span>{{ item.NombreRuta }}</span>
              </div>
            </div>

            <!--<div class="cellListP " style="min-width: 80px; width: 80px; ">
              <div class="cellListE1 "><span>{{item.Gestion}}</span></div>
            </div>

            <div class="cellListP " style="min-width: 80px; width: 80px; ">
              <div class="cellListE1 "><span>{{item.SKU}}</span></div>
            </div>-->

            <div class="cellListP" style="">
              <div class="cellListE1">
                <span>{{ item.AduanaEnSitio }}</span>
              </div>
            </div>

            <div
              class="cellListP"
              style="display: unset; width: 280px; min-width: 280px"
            >
              <div
                class="input-large input"
                style="margin-right: 10px; height: 34px; width: 100%"
              >
                <!--<ng-select class="cellGrid" [items]="item.listSitios" bindLabel="Sitio.Nombre" bindValue="Sitio.Id" [(ngModel)]="item.SitioReferenciaId"
             placeholder="Seleccionar Sitio" style="width: 100%" title="{{item.SitioReferenciaId}}">

  </ng-select>-->
                <mat-form-field>
                  <mat-label>Seleccionar Sitio</mat-label>
                  <mat-select [(ngModel)]="item.SitioReferenciaId">
                    <!-- Opción para borrar la selección -->
                    <mat-option [value]="null">Borrar Selección</mat-option>

                    <!-- Opciones normales -->
                    <mat-option
                      *ngFor="let sitio of item.listSitios"
                      [value]="sitio.SitioId"
                    >
                      {{ sitio.Sitio.Nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!--<ng-select class="cellGrid" [items]="item.listSitios" [(ngModel)]="item.SitioReferenciaId" bindLabel="Sitio.Nombre" bindValue="Sitio.Id"
                           placeholder="Seleccionar Sitio" style="width: 100%" [position]="'auto'">
                  <ng-template ng-option-tmp let-item2="item">
                    <span [title]="item2.Sitio.Nombre">{{ item2.Sitio.Nombre }}</span>
                  </ng-template>
                </ng-select>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="button" style="height: 100%" (click)="EventGuardar()">
    <img src="../../../assets/icons/save.png" alt="Alternate Text" />
    GUARDAR
  </button>
</mat-dialog-actions>

<div style="padding: 10px">
  <ng-container *ngFor="let item of listGrid">
    <div class="flexRowNoWrap ">
      <div class="flexRowNoWrap cellGrid rowGridE " style="">
        <div class="cellListP " style=" width: 40px; min-width: 40px;">
          <div class="cellListE1 c-pointer" (click)="item.Expandible = !item.Expandible">
            <img *ngIf="!item.Expandible" src="../../../assets/icons/arrowDownBlack1.png" alt="Alternate Text" />
            <img *ngIf="item.Expandible" src="../../../assets/icons/arrowUpBlack1.png" alt="Alternate Text" />
          </div>
        </div>
        <div class="cellListP " style=" width: 100px; min-width: 100px;">
          <div class="cellListE1 "><span>{{item.Descripcion}}</span></div>
        </div>

      </div>
      <div class="flexRowNoWrap" style=" width: 100%; height:40px">
        <div class="cellListP cellColor">
          <div class="cellListE1 ">{{item.Origen.Cantidad}}</div>
        </div>
        <div class="cellListP cellColor">
          <div class="cellListE1 ">{{item.Transito1.Cantidad}}</div>
        </div>
        <div class="cellListP cellColor">
          <div class="cellListE1 ">{{item.Frontera.Cantidad}}</div>
        </div>
        <div class="cellListP cellColor">
          <div class="cellListE1 ">{{item.Transito2.Cantidad}}</div>
        </div>
        <div class="cellListP cellColor">
          <div class="cellListE1 ">{{item.Destino.Cantidad}}</div>
        </div>

      </div>
    </div>


    <div *ngIf="item.Expandible">
      <ng-container *ngFor="let itemE of item.ListGrid2">
        <div class="flexRowNoWrap ">
          <div class="cellGrid rowGridE2 " style=" height: 30px;">
            <!--<div class="cellListP " style=" width: 40px; min-width: 40px;">
          <div class="cellListE1 " (click)="item.Expandible = !item.Expandible">
            <img *ngIf="!item.Expandible" src="../../../assets/icons/arrowDownBlack1.png" alt="Alternate Text" />
            <img *ngIf="item.Expandible" src="../../../assets/icons/arrowUpBlack1.png" alt="Alternate Text" />
          </div>
        </div>-->
            <div class="cellListP " style=" width: 100%; min-width: 100%;">
              <div class="cellListE1 "><span>{{itemE.Descripcion}}</span></div>
            </div>

          </div>
          <div class="flexRowNoWrap" style=" width: 100%; height:40px">
            <div class="cellListP cellColor" style="--widget_background_color: {{itemE.Origen.Color}}; " >
              <div class="cellListE1 ">&nbsp;</div>
            </div>
            <div class="cellListP cellColor" style="--widget_background_color: {{itemE.Transito1.Color}}; " >
              <div class="cellListE1 ">&nbsp;</div>
            </div>
            <div class="cellListP cellColor" style="--widget_background_color: {{itemE.Frontera.Color}}; " >
              <div class="cellListE1 ">&nbsp;</div>
            </div>
            <div class="cellListP cellColor" style="--widget_background_color: {{itemE.Transito2.Color}}; ">
              <div class="cellListE1 ">&nbsp;</div>
            </div>
            <div class="cellListP cellColor" style="--widget_background_color: {{itemE.Destino.Color}}; ">
              <div class="cellListE1 ">&nbsp;</div>
            </div>

          </div>
        </div>
        <div>

        </div>
      </ng-container>
    </div>
   
  </ng-container>
 
</div>

<div mat-dialog-title>
  <button mat-icon-button mat-dialog-close class="close-button" style="margin-left:auto">
    <img src="../../../assets/icons/cerrar.png" alt="Alternate Text" style="height:30px; width: 30px" />
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div class="flexRow ">
    <div class="chart" [class.fullscreen]="FullScreenGraph1" [class.halfscreen]="!FullScreenGraph1 && !FullScreenGraph2"  style="  padding: 5px;">
    <!--<div class="chart" [class.fullscreen]="isFullscreenChart1" *ngIf="(FullScreenGraph1 && !FullScreenGraph2) || (!FullScreenGraph1 && !FullScreenGraph2)" style=" width: 50%; padding: 5px;">-->
      <button *ngIf="!FullScreenGraph1" class="buttonTransparente float-right" mat-button (click)="FullScreenGraph1 = !FullScreenGraph1; EventRegenerateGRaph1() " >
        <img class="img32px" src="../../../assets/icons/maximizar.png" alt="Alternate Text" />
      </button>
      <button *ngIf="FullScreenGraph1" class="buttonTransparente float-right" mat-button (click)="FullScreenGraph1 = !FullScreenGraph1; EventRegenerateGRaph1()">
        <img class="img32px" src="../../../assets/icons/minimizar.png" alt="Alternate Text" />
      </button>
      <graph-by-analisis #GraphByAnalisis>

      </graph-by-analisis>
    </div>
    <div class="chart" [class.fullscreen]="FullScreenGraph2"  [class.halfscreen]="!FullScreenGraph1 && !FullScreenGraph2" style=" padding: 5px;">
      <!--<div class="chart" [class.fullscreen]="isFullscreenChart1" *ngIf="(!FullScreenGraph1 && FullScreenGraph2) || (!FullScreenGraph1 && !FullScreenGraph2)" style=" width: 50%; padding: 5px;">-->
        <button *ngIf="!FullScreenGraph2" class="buttonTransparente float-right" mat-button (click)="FullScreenGraph2 = !FullScreenGraph2; EventRegenerateGRaph2() ">
          <img class="img32px" src="../../../assets/icons/maximizar.png" alt="Alternate Text" />
        </button>
        <button *ngIf="FullScreenGraph2" class="buttonTransparente float-right" mat-button (click)="FullScreenGraph2 = !FullScreenGraph2; EventRegenerateGRaph2()">
          <img class="img32px" src="../../../assets/icons/minimizar.png" alt="Alternate Text" />
        </button>
        <graph-by-analisis #GraphByAnalisis2>

        </graph-by-analisis>
      </div>


    </div>

</mat-dialog-content>

import { Inject, Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http'
import { AUTHENTICATION } from '../inject-token/vesta-authentication.inject';
import { VestaOptions } from '../object/vesta-options.object';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CallHttpService {

  constructor(
    private http: HttpClient,
    @Inject(AUTHENTICATION) private authentication: VestaOptions) { }

    httpGet<TResponse>(url: string, param?: string, options?: { withCredentials: boolean }): Observable<TResponse> {
      let urlGet: string = (param == null) ? url : url + "?" + param;
    
      let optionsT = options != null ? options : { withCredentials: this.authentication.isAuthentication };

      var retorna:Observable<TResponse> = this.http.get<TResponse>(urlGet, optionsT).pipe(catchError(this.handleError<TResponse>('Error')));
  
      return retorna;
    }

    httpGetParams<TResponse>(url: string, params?: any, options?: { withCredentials: boolean }): Observable<TResponse> {
      let paramsObj = new HttpParams({ fromObject: params });
  
      let optionsT = options != null ? options : { withCredentials: this.authentication.isAuthentication };

      var retorna: Observable<TResponse> = this.http.get<TResponse>(url, { params: paramsObj, withCredentials: optionsT.withCredentials })
        .pipe(catchError(this.handleError<TResponse>("Error")));
      return retorna;
    }

    httpPost<TResponse>(url: string, contentBody: any = {}, options?: { withCredentials: boolean }): Observable<TResponse> {    
      
      let optionsT = options != null ? options : { withCredentials: this.authentication.isAuthentication };
  
      var retorna: Observable<TResponse> = this.http.post<TResponse>(url, contentBody, optionsT)
        .pipe(catchError(this.handleError<TResponse>("Error")));
      return retorna;
    }


    private handleError<T>(operation = 'operation') {
      return (error: HttpErrorResponse): Observable<T> => {
        console.error(error); // log to console instead

        let msj = "";
        if (error.error) {
          if (error.error.Message == undefined && error.statusText != "Unknown Error" && (error.status == 404 || error.status == 412)) {
            var keysObject = Object.keys(error.error);
            for (let k of keysObject) {
              msj += k + " - " + (error.error[k] as Array<string>).reduce((r, n, i) => r + "<br />" + n) + "<br />";
            }
          } else if (error.error.text) {
            msj = error.error.text;
          } else {
            msj = error.message;
          }

          if (error.error.Message != undefined) msj = error.error.Message;
          if (msj == "") msj = `${error.status} - ${error.statusText}`;
  
          //msj = error.error.Message != undefined ? error.error.Message : (msj == "") ? error.message : msj;
        } else {
          msj = error.message;
        }
  
        return throwError(() => new Error(msj));
      };
    }

}

import { Component, OnInit } from '@angular/core';
import Enumerable from 'linq';
import { CorteDiarioModel } from '../corte-diario.models';

@Component({
  selector: 'graph-grid-switch',
  templateUrl: './graph-grid-switch.component.html',
  styleUrls: ['./graph-grid-switch.component.css']
})
export class GraphGridSwitchComponent implements OnInit {

  constructor() { }
  public listGrid: Array<CorteDiarioModel.ListGridExpandibleGraph>=[];
  public listGestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> = [];

  ngOnInit(): void {
    //this.listGrid.push(
    //  {
    //    Expandible: false, Descripcion: 'Eventos', Origen: '0', Transito1: '0', Frontera: '10', Transito2: '5', Destino: '0',
    //    ListGrid2: [
    //      { Descripcion: 'Tipo Evento 1', Origen: 'white', Transito1: 'white', Frontera: '#43A047', Transito2: 'white', Destino: 'white'},
    //      { Descripcion: 'Tipo Evento 2', Origen: 'white', Transito1: 'white', Frontera: 'white', Transito2: '#66BB6A', Destino: 'white'},
    //      { Descripcion: 'Tipo Evento 3', Origen: 'white', Transito1: 'white', Frontera: '#81C784', Transito2: 'white', Destino: 'white'},
    //      { Descripcion: 'Tipo Evento 4', Origen: 'white', Transito1: 'white', Frontera: '#A5D6A7', Transito2: 'white', Destino: 'white' },
         
    //    ]
    //  },
    //  {
    //    Expandible: false, Descripcion: 'Alertas', Origen: '0', Transito1: '0', Frontera: '10', Transito2: '0', Destino: '0',
    //  },
    //  {
    //    Expandible: false, Descripcion: 'Incidencias', Origen: '0', Transito1: '0', Frontera: '10', Transito2: '0', Destino: '0',
    //  },
    //  {
    //    Expandible: false, Descripcion: 'Casos', Origen: '0', Transito1: '0', Frontera: '10', Transito2: '0', Destino: '0',
    //  }     


    //)

  }

  ngGenerateSectionInfoGraph() {
    if (this.listGestiones.length > 0) {
      this.listGrid = [];
      //if (this.listGestiones.some(s => s.Alertas.length>0)) {
      //  //existen alertas
      //  let sumInOrigen = 0;
      //  let sumInT1 = 0;
      //  let sumInT2 = 0;
      //  let sumInFrontera = 0;
      //  let sumInDestino = 0;
      //  this.listGestiones.forEach(gestion => {
      //    gestion.Alertas.forEach(alerta => {
      //      if (alerta?.SitioId == gestion.Segmentacion.Origen?.SitioId) {
      //        sumInOrigen += 1;
      //      }
      //      if (gestion.Segmentacion.TransitoUno.some(tuno => alerta?.SitioId == tuno?.SitioId)) {
      //        sumInT1 += 1;

      //      }
      //      if (alerta?.SitioId == gestion.Segmentacion.Frontera?.SitioId) {
      //        sumInFrontera += 1;

      //      }
      //      if (gestion.Segmentacion.TransitoDos.some(tuno => alerta?.SitioId == tuno?.SitioId)) {
      //        sumInT2 += 1;

      //      }
      //      if (alerta?.SitioId == gestion.Segmentacion.Destino?.SitioId) {
      //        sumInDestino += 1;

      //      }

      //    })
      //  });

      //  this.listGrid.push(
      //    {
      //      Expandible: false, Descripcion: 'Alertas', Origen: { Cantidad: sumInOrigen.toString() },
      //      Transito1: { Cantidad: sumInT1.toString() },
      //      Frontera: { Cantidad: sumInFrontera.toString() },
      //      Transito2: { Cantidad: sumInT2.toString() }, 
      //      Destino: { Cantidad: sumInDestino.toString() },
            
      //    }
      //  );

      //}

      //agregar eventos de prueba
      //this.listGestiones.forEach(gestion => {
      //  gestion.Eventos = [
      //    {
      //      Id: '1',
      //      Estado: 'Activo',
      //      GestionId: 'gestion1',
      //      Inicio: '2024-04-10T08:00:00',
      //      Fin: '2024-04-10T12:00:00',
      //      SitioId: '8378dc42-0e7d-46d7-b7f1-184d18506ad0',
      //      SitioNombre: 'Sitio A',
      //      SegmentoId: 'segmento1',
      //      EventoSeguimientoId: 'eventoSeguimiento1',
      //      EventoTipoId: 'tipoEvento1',
      //      EventoTipo: {
      //        Descripcion: 'Evento Tipo 1',
      //        Controlado: true
      //      }
      //    },
      //    {
      //      Id: '2',
      //      Estado: 'Inactivo',
      //      GestionId: 'gestion2',
      //      Inicio: '2024-04-11T10:00:00',
      //      Fin: '2024-04-11T14:00:00',
      //      SitioId: '8378dc42-0e7d-46d7-b7f1-184d18506ad0',
      //      SitioNombre: 'Sitio B',
      //      SegmentoId: 'segmento2',
      //      EventoSeguimientoId: 'eventoSeguimiento2',
      //      EventoTipoId: 'tipoEvento2',
      //      EventoTipo: {
      //        Descripcion: 'Evento Tipo 2',
      //        Controlado: false
      //      }
      //    },
      //    {
      //      Id: '3',
      //      Estado: 'Inactivo',
      //      GestionId: 'gestion2',
      //      Inicio: '2024-04-11T10:00:00',
      //      Fin: '2024-04-11T14:00:00',
      //      SitioId: '49fe924f-32e4-4c5b-8a97-15c9f112a98b',
      //      SitioNombre: 'Sitio B',
      //      SegmentoId: 'segmento2',
      //      EventoSeguimientoId: 'eventoSeguimiento2',
      //      EventoTipoId: 'tipoEvento3',
      //      EventoTipo: {
      //        Descripcion: 'Evento Tipo 3',
      //        Controlado: false
      //      }
      //    },
      //    {
      //      Id: '4',
      //      Estado: 'Inactivo',
      //      GestionId: 'gestion2',
      //      Inicio: '2024-04-11T10:00:00',
      //      Fin: '2024-04-11T14:00:00',
      //      SitioId: '8378dc42-0e7d-46d7-b7f1-184d18506ad0',
      //      SitioNombre: 'Sitio B',
      //      SegmentoId: 'segmento2',
      //      EventoSeguimientoId: 'eventoSeguimiento2',
      //      EventoTipoId: 'tipoEvento2',
      //      EventoTipo: {
      //        Descripcion: 'Evento Tipo 2',
      //        Controlado: false
      //      }

      //    },          
      //    {
      //      Id: '5',
      //      Estado: 'Inactivo',
      //      GestionId: 'gestion2',
      //      Inicio: '2024-04-11T10:00:00',
      //      Fin: '2024-04-11T14:00:00',
      //      SitioId: '8378dc42-0e7d-46d7-b7f1-184d18506ad0',
      //      SitioNombre: 'Sitio B',
      //      SegmentoId: 'segmento2',
      //      EventoSeguimientoId: 'eventoSeguimiento2',
      //      EventoTipoId: 'tipoEvento4',
      //      EventoTipo: {
      //        Descripcion: 'Evento Tipo 4',
      //        Controlado: false
      //      }

      //    },          
      //    {
      //      Id: '6',
      //      Estado: 'Inactivo',
      //      GestionId: 'gestion2',
      //      Inicio: '2024-04-11T10:00:00',
      //      Fin: '2024-04-11T14:00:00',
      //      SitioId: '8378dc42-0e7d-46d7-b7f1-184d18506ad0',
      //      SitioNombre: 'Sitio B',
      //      SegmentoId: 'segmento2',
      //      EventoSeguimientoId: 'eventoSeguimiento2',
      //      EventoTipoId: 'tipoEvento4',
      //      EventoTipo: {
      //        Descripcion: 'Evento Tipo 4',
      //        Controlado: false
      //      }

      //    },
      //    // Puedes agregar más objetos de prueba aquí
      //  ];

      //});

      if (this.listGestiones.some(s => s.Eventos.length > 0)) {
        //existen Eventos
        let sumInOrigen = 0;
        let sumInT1 = 0;
        let sumInT2 = 0;
        let sumInFrontera = 0;
        let sumInDestino = 0;
        this.listGestiones.forEach(gestion => {
       
          gestion.Eventos.forEach(evento => {
            if (evento?.SitioId == gestion.Segmentacion.Origen?.SitioId) {
              sumInOrigen += 1;
              evento.TipoSitioActual = 'Origen';
            } else
              if (gestion.Segmentacion.TransitoUno.some(tuno => evento?.SegmentoId == tuno?.SegmentoId)) {
              sumInT1 += 1;
              evento.TipoSitioActual = 'TransitoUno';

              } else
            if (gestion.Segmentacion.Frontera.some(f => evento?.SitioId == f?.SitioId)) {
              sumInFrontera += 1;
              evento.TipoSitioActual = 'Frontera';

            } else
            if (gestion.Segmentacion.TransitoDos.some(tuno => evento?.SegmentoId == tuno?.SegmentoId)) {
              sumInT2 += 1;
              evento.TipoSitioActual = 'TransitoDos';

            } else
            if (gestion.Segmentacion.Destino.some(d => evento?.SitioId == d?.SitioId)) {
              sumInDestino += 1;
              evento.TipoSitioActual = 'Destino';
            }

          })
        });
        //Agrupamos los eventos por el tipo de evento
        let groupByTipoEvento = Enumerable.from(this.listGestiones).selectMany(s => s.Eventos).groupBy(s => s.EventoTipoId).toArray();
        let listGrid2: Array<CorteDiarioModel.ListGridExpandibleGraph> = [];
        let cantOrigen = 0;
        let cantTUno = 0;
        let cantTDos = 0;
        let cantFrontera = 0;
        let cantDestino = 0;
        groupByTipoEvento.forEach(group => {
          let eventoGrupo = group.getSource();
          let groupEventoByTipoSitio = Enumerable.from(eventoGrupo).groupBy(s => s.TipoSitioActual).toArray();

       
          //agrupamos cada tipo de evento por el tipo de sitio donde esta(origen...)
          //luego obtenemos cuantos eventos estan en ese sitio
         // groupEventoByTipoSitio.forEach(evento => {
         //   let gEventoAgrupadoSitio = evento.getSource();

          cantOrigen = Enumerable.from(eventoGrupo).where(s => s.TipoSitioActual === 'Origen').toArray().length;
          cantTUno = Enumerable.from(eventoGrupo).where(s => s.TipoSitioActual === 'TransitoUno').toArray().length;
          cantTDos = Enumerable.from(eventoGrupo).where(s => s.TipoSitioActual === 'TransitoDos').toArray().length;
          cantFrontera = Enumerable.from(eventoGrupo).where(s => s.TipoSitioActual === 'Frontera').toArray().length;
          cantDestino = Enumerable.from(eventoGrupo).where(s => s.TipoSitioActual === 'Destino').toArray().length;

         // });


          listGrid2.push({
            Descripcion: eventoGrupo[0].EventoTipo.Descripcion,
            Origen: { Cantidad: (cantOrigen).toString(), Color: 'white' },
            Transito1: { Cantidad: (cantTUno).toString(), Color: 'white' },
            Frontera: { Cantidad: (cantFrontera).toString(), Color: 'white' },
            Transito2: { Cantidad: (cantTDos).toString(), Color: 'white' },
            Destino: { Cantidad: (cantDestino).toString(), Color: 'white' },
          });

        });
        //Sacar el color con la opacidad necesaria.
        console.log(listGrid2)

        let everyORigen = Enumerable.from(listGrid2).select(s => Number(s.Origen.Cantidad)).toArray().sort((a, b) => b - a);
        console.log(everyORigen)

        let arraywithColors = this.generateOpacidadColorEventos(everyORigen);
        console.log(arraywithColors);

        listGrid2.forEach(segmento => {
          let finItemColor = arraywithColors.find(s => s.Cantidad == segmento.Origen.Cantidad)?.Color;

          if (finItemColor) {
            segmento.Origen.Color = finItemColor
          }

        })

        let everyT1 = Enumerable.from(listGrid2).select(s => Number(s.Transito1.Cantidad)).toArray().sort((a, b) => b - a);
        console.log(everyT1)

        arraywithColors = this.generateOpacidadColorEventos(everyT1);
        console.log(arraywithColors);

        listGrid2.forEach(segmento => {
          let finItemColor = arraywithColors.find(s => s.Cantidad == segmento.Transito1.Cantidad)?.Color;

          if (finItemColor) {
            segmento.Transito1.Color = finItemColor
          }

        })

        let everyFrontera = Enumerable.from(listGrid2).select(s => Number(s.Frontera.Cantidad)).toArray().sort((a, b) => b - a);
        console.log(everyFrontera)

        arraywithColors = this.generateOpacidadColorEventos(everyFrontera);
        console.log(arraywithColors);

        listGrid2.forEach(segmento => {
          let finItemColor = arraywithColors.find(s => s.Cantidad == segmento.Frontera.Cantidad)?.Color;

          if (finItemColor) {
            segmento.Frontera.Color = finItemColor
          }

        })

        let everyT2 = Enumerable.from(listGrid2).select(s => Number(s.Transito2.Cantidad)).toArray().sort((a, b) => b - a);
        console.log(everyT2)

        arraywithColors = this.generateOpacidadColorEventos(everyT2);
        console.log(arraywithColors);

        listGrid2.forEach(segmento => {
          let finItemColor = arraywithColors.find(s => s.Cantidad == segmento.Transito2.Cantidad)?.Color;

          if (finItemColor) {
            segmento.Transito2.Color = finItemColor
          }

        })

        let everyDestino = Enumerable.from(listGrid2).select(s => Number(s.Destino.Cantidad)).toArray().sort((a, b) => b - a);
        console.log(everyDestino)

        arraywithColors = this.generateOpacidadColorEventos(everyDestino);
        console.log(arraywithColors);

        listGrid2.forEach(segmento => {
          let finItemColor = arraywithColors.find(s => s.Cantidad == segmento.Destino.Cantidad)?.Color;

          if (finItemColor) {
            segmento.Destino.Color = finItemColor
          }

        })

        console.log(arraywithColors);
        this.listGrid.push(
          {
            Expandible: false, Descripcion: 'Eventos',
            Origen: { Cantidad: (Enumerable.from(listGrid2).sum(s => Number(s.Origen.Cantidad) )).toString() },
            Transito1: { Cantidad: (Enumerable.from(listGrid2).sum(s => Number(s.Transito1.Cantidad))).toString() },
            Frontera: { Cantidad: (Enumerable.from(listGrid2).sum(s => Number(s.Frontera.Cantidad))).toString() },
            Transito2: { Cantidad: (Enumerable.from(listGrid2).sum(s => Number(s.Transito2.Cantidad))).toString() },
            Destino: { Cantidad: (Enumerable.from(listGrid2).sum(s => Number(s.Destino.Cantidad))).toString() },
            ListGrid2: listGrid2
          }
        );
        console.log(this.listGrid)

      }
     
    };
  }

  generateOpacidadColorEventos(arraySort: number[]) {
    let newItemwithColor = { Cantidad: '', Color: '#' };
    let arrayReturn: Array<CorteDiarioModel.ValuesBYSitio> = [];
    let max = arraySort[0];
    let opacidad = 1;
    arraySort.forEach((item, i) => {
      opacidad = item / max;

      //de mayor a menor
      if (i == 0 && item != 0) {
        //el primero siuempre tiene el color total
        arrayReturn.push({ Cantidad: item.toString(), Color: 'rgb(67, 160, 71)' })

      } else if (item == 0) {
        arrayReturn.push({ Cantidad: item.toString(), Color: 'white' });

      } else {
        arrayReturn.push({ Cantidad: item.toString(), Color: `rgb(67, 160, 71,${opacidad} )` })

      }

    });
    return arrayReturn;
  }
}
